import { SpecificDateTimeFormatOptions } from '@intlify/core-base'

const short: SpecificDateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}

const long: SpecificDateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
}

export default {
  en: {
    short,
    long,
  },
}
