/* eslint-disable @typescript-eslint/no-explicit-any */
import { useToast } from 'primevue/usetoast'
import { ComponentPublicInstance, Ref } from 'vue'

export interface ToastConfiguration {
  severity: string
  summary: string
  detail: string
  life: number
  closable: boolean
  styleClass: string
  contentStyleClass: string
  link?: {
    text: string
    href: string
  }
}

interface ToastServiceMethods {
  add(message: any): any
  removeGroup(group: any): void
  removeAllGroups(): void
}

let toasts: ToastServiceMethods
let toaster: Ref<ComponentPublicInstance | null | undefined>
const DEFAULT_TOAST_DURATION = 5 * 1000

const DEFAULT_CONFIG: Partial<ToastConfiguration> = {
  severity: 'error',
  life: DEFAULT_TOAST_DURATION,
  closable: true,
  contentStyleClass: 'toast-class',
}

export const init = (toasterRef: typeof toaster) => {
  toasts = useToast()
  toaster = toasterRef
}

export const showToast = (configuration: Partial<ToastConfiguration>) => {
  const toastConfiguration = {
    ...DEFAULT_CONFIG,
    ...configuration,
  }
  toasts.add(toastConfiguration)

  return {
    remove: () => (toaster.value as any)?.remove(toastConfiguration),
  }
}
