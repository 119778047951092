import { castToArray, isArrayEqual, once, someAsync } from '@/utils'
import { InjectionKey, Plugin, inject, onScopeDispose } from 'vue'
import { RouteLocationNormalized, useRouter } from 'vue-router'

type ConfirmationRequiredPredicate = () => boolean | Promise<boolean>
type NavigationConsentHandler = () => Promise<boolean>

type PageNavigationApi = {
  /**
   * This function will ask the user for navigation consent if it's necessary.
   * If it gets the consent or the consent is implicit (no parties require
   * user's consent) then `beforeReload` callback will be called and its result
   * awaited. Only then `location.reload()` will take place.
   *
   * If user denies then the callback won't be called and the
   * `requestPageReload` will resolve to `false`
   */
  requestPageReload(beforeReload?: () => Promise<void>): Promise<boolean>
}

const HANDLERS_TOKEN = Symbol() as InjectionKey<NavigationConsentHandler[]>
const PAGE_NAVIGATION_TOKEN = Symbol() as InjectionKey<PageNavigationApi>
const PREDICATES_TOKEN = Symbol() as InjectionKey<
  ConfirmationRequiredPredicate[]
>

export const NavigationConfirmation: Plugin = (app) => {
  const router = app.runWithContext(() => useRouter())
  const predicates: ConfirmationRequiredPredicate[] = []
  const handlers: NavigationConsentHandler[] = []

  app.provide(PREDICATES_TOKEN, predicates)
  app.provide(HANDLERS_TOKEN, handlers)
  app.provide(PAGE_NAVIGATION_TOKEN, {
    async requestPageReload(beforeReload) {
      const consent = await getNavigationConsentIfRequired()

      if (consent) {
        await beforeReload?.()
        location.reload()
      }

      return consent
    },
  })

  router.beforeEach(async (to, from, next) => {
    if (isSameRoute(to, from)) {
      next(true)
    } else {
      next(await getNavigationConsentIfRequired())
    }
  })

  async function getNavigationConsentIfRequired() {
    if (await isConsentRequired()) {
      return await getNavigationConsent()
    }

    return true
  }

  async function isConsentRequired() {
    return await someAsync(predicates, (pred) => pred())
  }

  async function getNavigationConsent() {
    if (handlers.length) {
      return await handlers[handlers.length - 1]()
    }
    throw Error(`No navigation consent handler while consent is requested`)
  }
}

export function useNavigationConsentHandler(handler: NavigationConsentHandler) {
  const handlers = inject(HANDLERS_TOKEN)!
  handlers.push(handler)

  const cleanupFn = once(() => {
    const idx = handlers.indexOf(handler)
    if (idx >= 0) handlers.splice(idx, 1)
  })

  onScopeDispose(() => {
    cleanupFn()
  })

  return cleanupFn
}

export function useNavigationConfirmWhen(
  predicate: () => boolean | Promise<boolean>,
) {
  const predicates = inject(PREDICATES_TOKEN)!
  predicates.push(predicate)

  const cleanupFn = once(() => {
    const idx = predicates.indexOf(predicate)
    if (idx >= 0) predicates.splice(idx, 1)
  })

  onScopeDispose(() => {
    cleanupFn()
  })

  return cleanupFn
}

export function usePageNavigation() {
  return inject(PAGE_NAVIGATION_TOKEN, {
    async requestPageReload(beforeReload) {
      await beforeReload?.()
      location.reload()

      return true
    },
  })
}

function isSameRoute(a: RouteLocationNormalized, b: RouteLocationNormalized) {
  if (a.name !== b.name) return false

  const aParams = Object.keys(a.params)
  const bParams = Object.keys(b.params)

  if (!isArrayEqual(aParams, bParams)) return false

  for (const key of Object.keys(a)) {
    const aValues = castToArray(a.params[key])
    const bValues = castToArray(b.params[key])

    if (!isArrayEqual(aValues, bValues)) return false
  }

  return true
}
