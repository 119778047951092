import { format } from 'date-fns'
import { stringFormatDate, stringFormatDateTime } from '@/utils'

export const formatDate = (dateFormat: DateFormat, date?: Date) => {
  return date ? format(new Date(date), dateFormat) : ''
}

export type DateFormat = typeof stringFormatDate
export type Date = number | string

export const formatDateTime = (dateTimeFormat: DateTimeFormat, date?: Date) => {
  return date ? format(new Date(date), dateTimeFormat) : ''
}

export type DateTimeFormat = typeof stringFormatDateTime
