import { UsersState } from './types'

export const state: UsersState = {
  isInternalLoading: false,
  isExternalLoading: false,
  internalUsersArray: [],
  externalUsersArray: [],
  userForEdit: undefined,
  internalFilters: {},
  externalFilters: {},
}
