import { logAnalyticsEvent } from '@/services/firebase.service'
import { SearchFilters } from '@/store/types'
import { AnalyticEvent } from '@/types/analyticEvents'
import { once } from '@/utils'
import { tryOnScopeDispose } from '@vueuse/core'
export { setUserDataForAnalytics } from '@/services/firebase.service'

type Params = Record<string, unknown>
const CONTEXT_PARAMS_PROVIDERS: (() => Params)[] = []

export const USED_FILTER_CONTENT = {
  careAreas: 'care_area',
  deviceGroups: 'device_group',
  patientGroups: 'patient_group',
  usages: 'usage',
  chapterAreas: 'product_cluster',
  more: 'more_filters',
} as const satisfies Record<keyof SearchFilters, string>

export function logEvent(event: AnalyticEvent, params?: Params) {
  const finalParams: Params = {}

  CONTEXT_PARAMS_PROVIDERS.forEach((getParams) => {
    Object.assign(finalParams, getParams())
  })

  Object.assign(finalParams, params)

  logAnalyticsEvent(event, finalParams)
}

export function useAnalyticsContext(getContextParams: () => Params) {
  CONTEXT_PARAMS_PROVIDERS.push(getContextParams)

  const stop = once(() => {
    const idx = CONTEXT_PARAMS_PROVIDERS.indexOf(getContextParams)
    if (idx >= 0) {
      CONTEXT_PARAMS_PROVIDERS.splice(idx, 1)
    }
  })

  tryOnScopeDispose(stop)

  return stop
}
