import { isSmallerThanSmall } from '@/helpers/useBreakpoints'
import useUserHelper from '@/helpers/useUserHelper'
import { NAVIGATION_CONFIG, NavigationConfigItem } from '@/routes/router.config'
import { getPersisted, setPersisted } from '@/store/persistenceHelper'
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const HEADER_CLASS_NAMES = {
  TRUE_CENTER: 'header__true-center',
  SEARCH_WRAPPER: 'header__search-wrapper',
}

const sideBarExpanded = ref(getPersisted()?.sideBarExpanded)

const useNavigationConfig = () => {
  const { role } = useUserHelper()
  const route = useRoute()
  const { t } = useI18n()

  const toggleSideBarState = (expand?: boolean | MouseEvent) => {
    if (typeof expand == 'object') {
      setPersisted({
        ...getPersisted(),
        sideBarExpanded: !sideBarExpanded.value,
      })

      sideBarExpanded.value = getPersisted()?.sideBarExpanded
    } else {
      setPersisted({
        ...getPersisted(),
        sideBarExpanded: !!expand,
      })
      sideBarExpanded.value = getPersisted()?.sideBarExpanded
    }
  }

  const sizes = reactive({
    browserWidth: window.innerWidth,
  })

  const browserResized = () => {
    sizes.browserWidth = window.innerWidth
  }

  const leftMarginOffset = computed(() =>
    sideBarExpanded.value ? '232px' : '68px',
  )

  watch(
    () => sizes.browserWidth,
    (curentValue) => {
      if (curentValue < 1500) {
        toggleSideBarState(false)
      }
    },
  )

  onMounted(() => {
    window.addEventListener('resize', browserResized)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', browserResized)
  })

  /**
   * Check current route name with main route name and all of its children
   */
  const isRouteActive = ({ children, main }: NavigationConfigItem) => {
    const childrenNames = children.map(({ name }) => name)

    return [main.name, ...childrenNames].includes(route.name as string)
  }

  const isHamburgerMenuVisible = computed(() => {
    return isSmallerThanSmall.value
  })

  /**
   * Prepare navigation config base on user role and visibility
   */
  const navigationConfig = computed(() => {
    return NAVIGATION_CONFIG.filter(({ main }) => {
      if (!main.allowedRoles) {
        return true
      }

      return role.value ? main.allowedRoles.includes(role.value) : false
    })
      .map((navigationItem) => {
        const isActive = isRouteActive(navigationItem)
        const label = t(`common.${navigationItem.main.name}`)

        return {
          ...navigationItem.main,
          iconName: navigationItem.iconName,
          label,
          class: { isActive },
        }
      })
      .filter((navigationItem) => {
        return isHamburgerMenuVisible.value
          ? true
          : navigationItem.meta?.isVisibleOnlyInHamburgerMenu !== true
      })
  })

  /**
   * CSS class for search wrapper: search bar in header can be on horizontal center of page or not
   */
  const searchWrapperClass = computed(() => {
    const isFewNavItems = navigationConfig.value.length < 2
    if (isHamburgerMenuVisible.value || isFewNavItems) {
      //return HEADER_CLASS_NAMES.TRUE_CENTER // TODO - Causes layout bugs, make sure if we need this class
      return HEADER_CLASS_NAMES.SEARCH_WRAPPER
    }

    return HEADER_CLASS_NAMES.SEARCH_WRAPPER
  })

  return {
    isHamburgerMenuVisible,
    searchWrapperClass,
    navigationConfig,
    toggleSideBarState,
    sideBarExpanded,
    leftMarginOffset,
  }
}

export default useNavigationConfig
