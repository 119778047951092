import { createStore } from 'vuex'

import { IS_DEV } from '@/flags'
import { vuexLogger } from '@/store/store.helpers'

import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'

import product from '@/routes/ProductDetails/store'
import searchResults from '@/routes/finder/SearchResults/store'
import productListDetails from '@/routes/productListDetails/store'
import productLists from '@/routes/productLists/store'
import system from '@/routes/system/store'
import users from '@/routes/users/store'
import notifications from './notifications'
import staticData from './staticData'

export * from './store.helpers'

export default createStore({
  strict: IS_DEV,
  actions,
  mutations,
  modules: {
    notifications,
    product,
    productListDetails,
    productLists,
    searchResults,
    system,
    staticData,
    users,
  },
  state,
  plugins: [vuexLogger('vuex')],
})
