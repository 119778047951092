/* eslint-disable no-console */
import { API } from '@/api'
import { isFirebaseConsentsGranted } from '@/helpers/getters'
import { getDynamicStore } from '@/store'
import { AnalyticEvent } from '@/types/analyticEvents'
import { deferred } from '@/utils'
import {
  getAnalytics,
  initializeAnalytics,
  isSupported as isAnalyticSupported,
  logEvent,
  setUserId,
  setUserProperties,
} from 'firebase/analytics'
import { FirebaseApp, getApp, initializeApp } from 'firebase/app'
import {
  MessagePayload,
  deleteToken,
  getMessaging,
  getToken,
  isSupported as isFcmSupported,
  onMessage,
} from 'firebase/messaging'
import { register } from 'register-service-worker'

const FIREBASE_ANALYTICS_ENABLED =
  process.env.VUE_APP_FIREBASE_ANALYTICS_ENABLED

const isAnalyticsEnabledByUser = async () => {
  const store = await getDynamicStore()

  return isFirebaseConsentsGranted(store.state)
}

const isAnalyticsEnabled = async () => {
  return (
    FIREBASE_ANALYTICS_ENABLED !== 'false' && (await isAnalyticsEnabledByUser())
  )
}

let firebaseApp: FirebaseApp | undefined

const getFirebaseMessaging = async () => {
  return (await isFcmSupported()) && firebaseApp
    ? getMessaging(firebaseApp)
    : null
}

const getFirebaseAnalytics = async () => {
  return (await isAnalyticsEnabled()) &&
    (await isAnalyticSupported()) &&
    firebaseApp
    ? getAnalytics(firebaseApp)
    : null
}

export const logAnalyticsEvent = async (
  eventName: AnalyticEvent,
  eventParams?: Record<string, unknown>,
) => {
  if (!(await isAnalyticsEnabled())) {
    console.warn(`Firebase Analytics event suppressed:`, eventName, eventParams)

    return
  }

  if (!(await isAnalyticSupported())) {
    console.warn('Firebase Analytics not supported')

    return
  }

  const analytics = firebaseApp && getAnalytics(firebaseApp)
  if (!analytics) {
    console.warn('Firebase Analytics not initialized')

    return
  }

  logEvent(analytics, eventName as string, eventParams)
}

export const setUserDataForAnalytics = async (
  user: API.DisplayUserProfileResponseDto,
) => {
  const analytics = await getFirebaseAnalytics()
  if (!analytics) {
    console.warn('Firebase Analytics not initialized or not supported')

    return
  }

  const userProperties = Object.fromEntries(
    Object.entries({
      department: user.organizationRole,
      role: user.role,
      language: user.language,
      country: user.country,
      currency: user.currency,
      user_type: user.userType,
      pseudo_user_id: user.id,
    }).filter(([_, value]) => value),
  )

  const options = { global: true }
  setUserId(analytics, '' + user.id, options)
  setUserProperties(analytics, userProperties, options)
}

const [registration, setRegistration] = deferred<ServiceWorkerRegistration>()

/**
 Register service worker for Firebase Messaging and for PWA.
 */
const registerServiceWorker = () => {
  register(`${process.env.BASE_URL}serviceWorkerFcm.js`, {
    ready(registration) {
      console.log('Service worker is ready.')
      setRegistration(registration)
    },
    registered(registration) {
      console.log('New service worker has been registered.')
      setRegistration(registration)
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated() {
      console.log('New content is available; please refresh.')
      setTimeout(() => {
        location.reload()
      }, 100)
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.',
      )
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
}

const checkAnalyticsInitialized = async () => {
  return !(await isAnalyticsEnabled()) || (await getFirebaseAnalytics())
}

export const initializeFirebaseAnalytics = async () => {
  if (await checkAnalyticsInitialized()) {
    return
  }

  const firebaseApp = getApp()

  initializeAnalytics(firebaseApp, {
    config: {
      send_page_view: false,
    },
  })
  await getFirebaseAnalytics() // Initialize analytics
}

export const initFirebase = async () => {
  if (!('serviceWorker' in navigator)) {
    // Service worker support check
    console.warn('Service worker is not supported')
  } else {
    registerServiceWorker()
  }

  const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  }

  firebaseApp = initializeApp(firebaseConfig)

  if (!(await isFcmSupported())) {
    console.warn('Firebase Messaging is not supported')

    return
  }
  await getFirebaseMessaging() // Initialize messaging
  console.log('[firebase.service.ts] Initialized')
}

/**
 * Gets the FCM token that can be used to associate it with the logged user to later receive notifications
 * Asks user for permission to access Notifications beforehand if the permission is not granted
 * Throws an error if the user didn't grant the permission
 * This is the real point of initialization FCM
 */
export const getFcmToken = async () => {
  const messaging = await getFirebaseMessaging()

  if (!messaging) {
    console.error('Firebase Messaging not initialized or not supported')

    return null
  }
  const options = {
    vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY,
    serviceWorkerRegistration: await registration,
  }
  try {
    return await getToken(messaging, options)
  } catch (e) {
    console.error(
      "User declined notification permission - can't get FCM token",
      e,
    )

    return null
  }
}

export const deleteFcmToken = async () => {
  const messaging = await getFirebaseMessaging()
  if (!messaging) {
    return
  }
  const isSuccessfully = await deleteToken(messaging)
  console.log('[firebase.service.ts] Token deletion status: ', isSuccessfully)

  return isSuccessfully
}

/**
 * On foreground message - while the app's browser tab is in focus
 * @param fn Handler to be called when foreground push message is received
 */
export const onForegroundMessage = async (
  fn: (msg: MessagePayload) => void,
) => {
  const messaging = await getFirebaseMessaging()
  if (!messaging) {
    return
  }
  onMessage(messaging, fn)
}

/**
 * On background message - while the app's browser tab is either opened
 * but not currently in focus, or when the app is not opened at all
 * @param fn Handler to be called when background push message is received
 */
export const onBackgroundMessage = async (
  pushCb: (msg: MessagePayload) => void,
  clickCb?: (msg: MessagePayload) => void,
) => {
  const messaging = await getFirebaseMessaging()
  if (!messaging) {
    return
  }
  navigator.serviceWorker.addEventListener('message', async (ev) => {
    console.log('[firebase.service.ts] Message event:', ev)
    if (ev.data?.messageType === 'notification-clicked') {
      console.log('[firebase.service.ts] Push clicked event:', ev)
      clickCb?.(ev.data)
    }
    if (ev.data?.type === 'FCM_BG_PUSH') {
      console.log('[firebase.service.ts] Background message event:', ev)
      pushCb(ev.data.message)
    }
  })
}
/* eslint-enable no-console */
