import { API } from '@/api'

export const scpRole: API.DisplayUserProfileResponseDto['role'] =
  'SALES_CHANNEL_PARTNER'

export const keyUserRole: API.DisplayUserProfileResponseDto['role'] = 'KEY_USER'

export const adminRole: API.DisplayUserProfileResponseDto['role'] = 'ADMIN'

export const allRoles: API.DisplayUserProfileResponseDto['role'][] = [
  'FIREBASE',
  'ADVANCED_USER',
  'ADVANCED_USER_PLUS',
  'BASIC_USER',
  'BASIC_USER_PLUS',
  keyUserRole,
  scpRole,
  adminRole,
]

export const allRolesExcept = (
  roles: API.DisplayUserProfileResponseDto['role'][],
): API.DisplayUserProfileResponseDto['role'][] => {
  return allRoles.filter((role) => !roles.includes(role))
}
