import { LG_PX, MD_PX, SM_PX, XS_PX } from '@/helpers/useBreakpoints'

export const CAROUSEL_CONFIG = {
  numScroll: 6,
  numVisible: 6,
}

export const CAROUSEL_RESPONSIVE_OPTIONS = [
  {
    breakpoint: `${LG_PX}px`,
    numVisible: 5,
    numScroll: 5,
  },
  {
    breakpoint: `${MD_PX}px`,
    numVisible: 3,
    numScroll: 3,
  },
  {
    breakpoint: `${SM_PX}px`,
    numVisible: 2,
    numScroll: 2,
  },
  {
    breakpoint: `${XS_PX}px`,
    numVisible: 1,
    numScroll: 1,
  },
]

/* the function to prevent error "Invalid array length" when
    the number of items is lower than set numVisible value */
export const getCarouselConfig = (listLength: number) => {
  return {
    ...CAROUSEL_CONFIG,
    numVisible: Math.min(listLength, CAROUSEL_CONFIG.numVisible),
  }
}

export const getCurrentResponsiveOption = () => {
  for (let i = 0; i < CAROUSEL_RESPONSIVE_OPTIONS.length - 1; i++) {
    const thisWidth = parseInt(CAROUSEL_RESPONSIVE_OPTIONS[i].breakpoint)
    const nextWidth = parseInt(CAROUSEL_RESPONSIVE_OPTIONS[i + 1].breakpoint)

    if (i === 0 && window.innerWidth > thisWidth) {
      return CAROUSEL_CONFIG
    }

    if (
      i === CAROUSEL_RESPONSIVE_OPTIONS.length - 2 ||
      (window.innerWidth > nextWidth && window.innerWidth <= thisWidth)
    ) {
      return CAROUSEL_RESPONSIVE_OPTIONS[i]
    }
  }

  return CAROUSEL_CONFIG
}
