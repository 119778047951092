import {
  FilterItem,
  FilterKey,
  FinderFilterType,
  getLeafFilters,
  getLeafsByValues,
} from '@/store/types'
import {
  devicesAnesthesia,
  devicesMonitoring,
  devicesOther,
  devicesThermoregulation,
  devicesVentilation,
} from './store/devices.config'
import {
  monitoringAccessories,
  neonatalCare,
  railAccessories,
  sensors,
  sodaLime,
  ventilationAccessories,
  wardEquipment,
} from './store/productCluster.config'
import { careArea, getDefaultSearchFilters } from './store/store.config'

const mappingConfig: Record<FinderFilterType, FilterKey[]> = {
  neonatalCare: getFiltersKeys(careArea(), ['NICU']),
  intensiveCare: getFiltersKeys(careArea(), ['ICU']),
  operatingRoom: getFiltersKeys(careArea(), ['OR']),
  anesthesia: getLeafFilterKeys(devicesAnesthesia()),
  monitoring: getLeafFilterKeys(devicesMonitoring()),
  monitoringAccessories: getLeafFilterKeys(monitoringAccessories()),
  others: getLeafFilterKeys(devicesOther()),
  railAccessories: getLeafFilterKeys(railAccessories()),
  sensors: getLeafFilterKeys(sensors()),
  sodaLime: getLeafFilterKeys(sodaLime()),
  thermoregulation: getLeafFilterKeys(devicesThermoregulation()),
  ventilation: getLeafFilterKeys(devicesVentilation()),
  ventilationAccessories: getLeafFilterKeys(ventilationAccessories()),
  wardEquipment: getLeafFilterKeys(wardEquipment()),
  productNeonatalCare: getLeafFilterKeys(neonatalCare()),
}

export function getFilterKeysByName(filterValueOrFinderFilterType: string) {
  if (Object.hasOwn(mappingConfig, filterValueOrFinderFilterType)) {
    return mappingConfig[filterValueOrFinderFilterType as FinderFilterType]
  }

  return getFiltersKeys(
    Object.values(getDefaultSearchFilters()),
    filterValueOrFinderFilterType,
  )
}

function getFiltersKeys<T>(
  item: FilterItem<T> | FilterItem<T>[],
  value: T | T[],
): FilterKey[] {
  const values = Array.isArray(value) ? value : [value]
  const items = Array.isArray(item) ? item : [item]

  return items
    .flatMap((filter) => getLeafsByValues(filter, values))
    .map((filter) => filter.key)
}

function getLeafFilterKeys(item: FilterItem) {
  return getLeafFilters(item).map((filter) => filter.key)
}
