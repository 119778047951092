import { SystemState } from './types'

export const state: SystemState = {
  importStatuses: [],
  areImportStatusesLoading: false,
  pageNumber: 0,
  pageSize: 10,
  totalCount: 0,
  pendingSynchronize: false,
  importsHistory: [],
  isImportsHistoryLoading: false,
	synchronizationStatus: {
		synchronizationInProgress: false,
		synchronizationIsPossible: false
	}
}
