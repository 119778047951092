import mitt from 'mitt'

const emitter = mitt()

export enum EmitterEvents {
  SHOW_COUNTRY_AND_CURRENCY_MODAL = 'SHOW_COUNTRY_AND_CURRENCY_MODAL',
  SAVE_PRODUCT_LIST_CUSTOM_ORDER = 'SAVE_PRODUCT_LIST_CUSTOM_ORDER',
  CHANGED_PRODUCTS_ORDER = 'CHANGED_PRODUCTS_ORDER',
  UNCHANGED_PRODUCTS_ORDER = 'UNCHANGED_PRODUCTS_ORDER',
}

/**
 * Emit event to open Country and Currency Modal from any place
 */
export const emitShowCountryAndCurrencyModal = () => {
  emitter.emit(EmitterEvents.SHOW_COUNTRY_AND_CURRENCY_MODAL)
}

/**
 * Handler that need to be used in component when opening of the modal is possible
 */
export const registerCallbackForShowCountryAndCurrencyModal = (
  callback: () => void,
) => {
  // Listen on the event and after that call the `callback` function
  emitter.on(EmitterEvents.SHOW_COUNTRY_AND_CURRENCY_MODAL, callback)
}

/**
 * Emit event when changed any product order
 */
export const emitChangedProductsOrder = () => {
  emitter.emit(EmitterEvents.CHANGED_PRODUCTS_ORDER)
}

/**
 * Handler that need to be used in component when changed proucts order
 */
export const registerCallbackChangedProductsOrder = (callback: () => void) => {
  // Listen on the event and after that call the `callback` function
  emitter.on(EmitterEvents.CHANGED_PRODUCTS_ORDER, callback)
}

/**
 * Emit event when list is same as was before editing
 */
export const emitFirstProductsOrder = () => {
  emitter.emit(EmitterEvents.UNCHANGED_PRODUCTS_ORDER)
}

/**
 * Handler that need to be used when list is same as was before editing
 */
export const registerCallbackFirstProductsOrder = (callback: () => void) => {
  // Listen on the event and after that call the `callback` function
  emitter.on(EmitterEvents.UNCHANGED_PRODUCTS_ORDER, callback)
}
