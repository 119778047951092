import { API } from '@/api'
import { i18n } from '@/i18n'
import store from '@/store'
import { SortingType } from './store/types'

interface SingleSorting {
  sortFunction: (a: ProductListDto, b: ProductListDto) => number
  label: string
}

type ProductListDto =
  | API.ProductListRowResponseDto
  | API.CountryShareDto
  | API.PinnedListResponseDto
  | API.ShareDraftDto

export const DEFAULT_SORT_TYPE = 'LAST_MODIFIED'

export enum ListDisplayTypes {
  pinned = 'pinned',
  personal = 'personal',
  shared = 'shared',
  draft = 'draft',
}

export type SortingOptions = {
  items: {
    label: string
    value: SortingType
    isSelected: boolean
    command: () => void
  }[]
}

export const CREATE_LIST_TYPE = {
  new: 'new',
  saveAsMine: 'saveAsMine',
}

export const SORTING_TYPES: Record<SortingType, SingleSorting> = {
  CREATION_DATE: {
    sortFunction: (a: ProductListDto, b: ProductListDto) => {
      return b.createdAt - a.createdAt
    },
    label: `common.creationDate`,
  },
  ALPHABETICAL: {
    sortFunction: (a: ProductListDto, b: ProductListDto) => {
      return a.title.localeCompare(b.title)
    },
    label: `common.alphabeticalOrder`,
  },
  MIN_AMOUNT_OF_PRODUCTS: {
    sortFunction: (a: ProductListDto, b: ProductListDto) => {
      return a.numberOfProducts - b.numberOfProducts
    },
    label: `common.minimumAmountOfProducts`,
  },
  MAX_AMOUNT_OF_PRODUCTS: {
    sortFunction: (a: ProductListDto, b: ProductListDto) => {
      return b.numberOfProducts - a.numberOfProducts
    },
    label: `common.maximumAmountOfProducts`,
  },
  LAST_MODIFIED: {
    sortFunction: (a: ProductListDto, b: ProductListDto) => {
      if (!a.lastUpdatedAt || !b.lastUpdatedAt) {
        return b.createdAt - a.createdAt
      } else {
        return b.lastUpdatedAt - a.lastUpdatedAt
      }
    },
    label: `common.lastModified`,
  },
}

export const getSortingOptionsConfig = (
  currentSortingType: SortingType,
): SortingOptions[] => {
  const isSelected = (sortingType: SortingType) => {
    return currentSortingType === sortingType
  }

  return [
    {
      items: [
        {
          label: i18n.global.t('common.dateOfCreation'),
          value: 'CREATION_DATE',
          isSelected: isSelected('CREATION_DATE' as SortingType),
          command: () => {
            store.dispatch(
              'productLists/setListSortingType',
              'CREATION_DATE' as SortingType,
            )
          },
        },
        {
          label: i18n.global.t('common.alphabeticalOrder'),
          value: 'ALPHABETICAL',
          isSelected: isSelected('ALPHABETICAL' as SortingType),
          command: () => {
            store.dispatch(
              'productLists/setListSortingType',
              'ALPHABETICAL' as SortingType,
            )
          },
        },
        {
          label: i18n.global.t('common.minimumAmountOfProducts'),
          value: 'MIN_AMOUNT_OF_PRODUCTS',
          isSelected: isSelected('MIN_AMOUNT_OF_PRODUCTS' as SortingType),
          command: () => {
            store.dispatch(
              'productLists/setListSortingType',
              'MIN_AMOUNT_OF_PRODUCTS' as SortingType,
            )
          },
        },
        {
          label: i18n.global.t('common.maximumAmountOfProducts'),
          value: 'MAX_AMOUNT_OF_PRODUCTS',
          isSelected: isSelected('MAX_AMOUNT_OF_PRODUCTS' as SortingType),
          command: () => {
            store.dispatch(
              'productLists/setListSortingType',
              'MAX_AMOUNT_OF_PRODUCTS' as SortingType,
            )
          },
        },
        {
          label: i18n.global.t('common.lastModified'),
          value: 'LAST_MODIFIED',
          isSelected: isSelected('LAST_MODIFIED' as SortingType),
          command: () => {
            store.dispatch(
              'productLists/setListSortingType',
              'LAST_MODIFIED' as SortingType,
            )
          },
        },
      ],
    },
  ]
}
