import { ProductListType } from '@/routes/productListDetails/store/types'
import { UsersType } from '@/routes/users/store/types'
import { UserSortingType } from '@/store/types'

export const API_BASE = `${process.env.VUE_APP_HCA_V2_BACKEND_URL}/hca-client-api`

export const LOGIN_PAGE = `${API_BASE}/login`
export const OKTA_LOGIN_PAGE = `${API_BASE}/login?okta=true`
export const LOGOUT_PAGE = `${API_BASE}/logout?mobile=false`
export const PASSWORD_RESET_PAGE = `${API_BASE}/password/reset`

export const STAGE = process.env.VUE_APP_STAGE as string

const URL_FOR_LIST_TYPE = {
  countryShare: 'country-sharing/list',
  privateShare: 'private-sharing/list',
  personal: 'product-lists/list',
  draft: 'share-draft',
}

export const getPdfLink = (projectListId: number, listType: ProductListType) =>
  `${API_BASE}/${URL_FOR_LIST_TYPE[listType]}/${projectListId}/download-pdf`

export const getXlsxLink = (projectListId: number, listType: ProductListType) =>
  `${API_BASE}/${URL_FOR_LIST_TYPE[listType]}/${projectListId}/download-xlsx`

export const getUsersCsvLink = (
  usersType: UsersType,
  sorting?: UserSortingType,
) => {
  const plainUrl = `${API_BASE}/users/list/download-${usersType}-csv`

  if (sorting === 'LAST_SEEN_AT') {
    return `${plainUrl}?sortBy=lastSeenAt&sortOrder=desc`
  }

  if (sorting === 'NAME') {
    return `${plainUrl}?sortBy=username&sortOrder=asc`
  }

  return plainUrl
}

// The response URL `error` param for password reset
export const ACCESS_DENIED_ERROR_CODE = 'access_denied'
export const PASSWORD_RESET_PREFIX_CODE = 'AADB2C90118'

export const ERROR_CODES = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  BUSINESS_ERROR: 409,
  OPTIMISTIC_LOCKING: 428,
  VALIDATION_ERROR: 460,
  RESOURCE_NOT_FOUND: 464,
  INTERNAL_SERVER_ERROR: 500,
} as const

export type ErrorCodes = (typeof ERROR_CODES)[keyof typeof ERROR_CODES]

export enum ERROR_SOURCE {
  DRAFT = 'draft',
}
