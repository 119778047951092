import { API } from '@/api'
import { onlyInvalidShowError, useAppForm } from '@/components/AppForm'
import * as yup from 'yup'
import { t } from '@/i18n'
import { MaybeRef } from 'vue'
import { PartialDeep } from 'type-fest'
import {
  UniversalUsersEmailHistory,
  UniversalUsersEmailHistoryEntry,
  UsersEmailsHistoryEntry,
} from '@/store/types'

export const maxNoteLength = 250

export type DropdownTypes = 'autocomplete' | 'history' | 'subregion' | 'country'

export const OShareDialogType = {
  INDIVIDUAL: 'INDIVIDUAL',
  COUNTRY: 'COUNTRY',
  SCP_USER: 'SCP_USER',
} as const

export type ShareDialogType =
  (typeof OShareDialogType)[keyof typeof OShareDialogType]

export const DropdownsRecordTypes: Record<DropdownTypes, string> = {
  autocomplete: 'autocomplete',
  history: 'history',
  subregion: 'subregion',
  country: 'country',
}

export const dropdownAutocomplete: DropdownTypes = 'autocomplete'

export type SharingOptionsItem = UsersEmailsHistoryEntry | API.CountryDto

export type ShareDialogSelectedUserType = UniversalUsersEmailHistoryEntry

export type SharingOptionsArray = UniversalUsersEmailHistory | API.CountryDto[]

export type AllDropdownItems = {
  country?: string
  id?: number
  title?: string
  adId: string
  username?: string
}

type CountriesFormPartial = {
  subregions: API.SubregionDto[]
  countries: API.CountryDto[]
}

type UsersFormPartial = {
  users: ShareDialogSelectedUserType[]
}

type FormBase = {
  note?: string
}

type UsersForm = FormBase & UsersFormPartial
type CountriesForm = FormBase & CountriesFormPartial

type Form<T extends ShareDialogType = 'INDIVIDUAL'> = T extends 'COUNTRY'
  ? CountriesForm
  : UsersForm

const getValidationSchema = <T extends ShareDialogType = 'INDIVIDUAL'>(
  shareDialogType: T,
) => {
  if (shareDialogType === OShareDialogType.COUNTRY) {
    return yup.object({
      note: yup.string().max(maxNoteLength).required(),
      countries: yup
        .array(yup.object())
        .test(
          'has-subregions',
          t('productList.share.subregion.no_countries_validation_message'),
          (value, ctx) => {
            const parent = ctx.parent as CountriesForm

            const countries = (value ?? []) as API.CountryDto[]
            const subregions = parent.subregions ?? []

            const subregionsWithoutCountries = subregions.filter(
              (subregion) => {
                return !subregion.countries.some((country) =>
                  countries.some((c) => c.id === country.id),
                )
              },
            )

            return !subregionsWithoutCountries.length
          },
        ).min(1, t('validation.required')),
      subregions: yup.array(yup.object()),
    })
  }

  return yup.object({
    note: yup.string().max(maxNoteLength).required(),
    users: yup.array(yup.object()).min(1, t('validation.required')),
  })
}

export function useShareForm<T extends ShareDialogType = 'INDIVIDUAL'>(
  shareDialogType: T,
  initialValues: MaybeRef<PartialDeep<Form<T>> | null | undefined>,
) {
  const validationSchema = getValidationSchema<T>(shareDialogType)

  return useAppForm<Form<T>>({
    formName: 'shareProductList',
    validationSchema,
    validateOnMount: false,
    keepValuesOnUnmount: false,
    initialValues: initialValues,
    showError: onlyInvalidShowError(),
  })
}

export function isCountryShareForm(
  form: UsersForm | CountriesForm,
): form is CountriesForm {
  return 'countries' in form
}

export function isUserShareForm(
  form: UsersForm | CountriesForm,
): form is UsersForm {
  return 'users' in form
}
