export const getCookie = (name: string) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const [key, value] = cookies[i].trim().split('=');
    if (key === name) {
      return value;
    }
  }

  return null;
}

export const saveCookie = (cookieName: string, cookieValue?: string | boolean) => {
  if (!cookieName) return
  if (!cookieValue) return

  document.cookie = `${cookieName}=${cookieValue};`
}

export const removeCookie = (cookieName: string) => {
  if (!cookieName) return

  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}
