import { computed } from 'vue'

export const useIsContentMaxLengthExceeded = (
  label?: string,
  length?: number,
) => {
  if (label && length) {
    return (label?.length ?? 0) > length
  }

  return false
}

export const useTruncateContent = (label?: string, length?: number) =>
  computed(() => {
    if (label && length && useIsContentMaxLengthExceeded(label, length)) {
      return `${label.slice(0, length)}...`
    }

    return label
  })
