import { useAppForm } from '@/components/AppForm'
import * as yup from 'yup'

type Form = {
  title: string
  description: string | undefined
}

export const maxTitleLength = 200

export const maxDescriptionLength = 500

const getValidationSchema = () => {
  return yup.object({
    title: yup.string().required().trim(),
    description: yup.string(),
  })
}

export function useEditListForm(initialValues: Form) {
  return useAppForm({
    formName: 'editProductList',
    validationSchema: getValidationSchema(),
    validateOnMount: false,
    keepValuesOnUnmount: false,
    initialValues: initialValues,
  })
}
