import { API } from '@/api'
import { ROUTER_CONFIG } from '@/routes/router.config'
import { logEvent } from '@/services/analytics.service'
import { useAppStore } from '@/store'
import { AnalyticEvent } from '@/types/analyticEvents'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import * as Getters from './getters'
import { checkCanEnter } from './getters'

const useUserHelper = () => {
  const store = useAppStore()
  const router = useRouter()

  const user = computed(() => {
    return store.state.user
  })

  const isKeyUser = computed(() => {
    return Getters.isKeyUser(store.state)
  })

  const isAdmin = computed(() => {
    return Getters.isAdmin(store.state)
  })

  const isKeyUserOrAdmin = computed(() => {
    return Getters.isKeyUserOrAdmin(store.state)
  })

  const isSalesChannelPartner = computed(() => {
    return Getters.isSalesChannelPartner(store.state)
  })

  const isBasicUser = computed(() => {
    return Getters.isBasicUser(store.state)
  })

  const isBasicPlusUser = computed(() => {
    return Getters.isBasicPlusUser(store.state)
  })

  const isAdvancedUser = computed(() => {
    return Getters.isAdvancedUser(store.state)
  })

  const role = computed(() => {
    return store.state.user?.role
  })

  const currencyShortcut = computed(() => {
    return store.state.user?.currencyShortcut
  })

  const currencyId = computed(() => {
    return store.state.user?.currencyId
  })

  const currency = computed(() => {
    return store.state.user?.currency
  })

  const currentCountry = computed(() => {
    return store.state.user?.country
  })

  const separator = computed(() => {
    return store.state.user?.separator
  })

  const localeOfSeparator = computed(() => {
    return separator.value === 'COMMA' ? 'de' : 'en'
  })

  const settingsLabel = computed(() => {
    return `${store.state.user?.countryShortcut} · ${currencyShortcut.value}`
  })

  const canEnterUsersView = computed(() => {
    return checkCanEnter(store.state, ROUTER_CONFIG.users.allowedRoles)
  })

  const canEnterSystemView = computed(() => {
    return checkCanEnter(store.state, ROUTER_CONFIG.system.allowedRoles)
  })

  const keyUserCountryRoles = computed(() => {
    if (!user.value?.roles) return []

    return user.value?.roles.filter((role) => role.role === 'KEY_USER')
  })

  const keyUserCountries = computed(() => {
    return keyUserCountryRoles.value.map((country) => country.countryId)
  })

  const canShowPrices = computed(() => {
    return (
      user.value?.showPrices &&
      !isBasicUser.value &&
      !isSalesChannelPartner.value
    )
  })

  const userCanSeeGGP = computed(
    () => canShowPrices.value && !isBasicPlusUser.value,
  )

  const userCanSeeGGPValue = computed(
    () =>
      canShowPrices.value && !isAdvancedUser.value && !isBasicPlusUser.value,
  )

  const allUserCountries = computed(() => {
    if (!store.state.user?.roles) return []

    return store.state.user.roles.map((role) => role.countryId)
  })

  const getAvatarLabel = (name: string | undefined) => {
    return name
      ?.split(' ')
      .map((word, index) => (index < 2 ? word[0] : undefined))
      .join('')
  }

  const userAvatarLabel = computed(() => getAvatarLabel(user.value?.username))

  const isFirebaseConsentsGranted = computed(() => {
    // this is for computed to update, because this getter only is not enough
    if (!store.state.persisted) return

    return Getters.isFirebaseConsentsGranted(store.state)
  })

  const showFirebaseConsentsPopup = computed(() => {
    return isFirebaseConsentsGranted.value === undefined
  })

  const isListDetailsPinningTutorialShowed = computed(() => {
    if (!store.state.persisted) return

    return Getters.isListDetailsPinningTutorialShowed(store.state)
  })

  const isCuratedListDetailsPinningTutorialShowed = computed(() => {
    if (!store.state.persisted) return

    return Getters.isCuratedListDetailsPinningTutorialShowed(store.state)
  })

  const isMultiProductsToMultiListsTutorialShowed = computed(() => {
    if (!store.state.persisted) return

    return Getters.isMultiProductsToMultiListsTutorialShowed(store.state)
  })

  async function updateUser(payload: Partial<API.BaseUserProfileDto>) {
    const prev = store.state.user
    if (!prev) return

    await store.dispatch('updateUser', payload)
    const curr = store.state.user!
    const isChanged = (k: keyof API.BaseUserProfileDto) => {
      return k in payload && prev[k] !== curr[k]
    }

    if (isChanged('currencyId')) {
      logEvent(AnalyticEvent.CHANGE_CURRENCY, {
        from: prev.currency,
        to: curr.currency,
      })
    }

    if (isChanged('countryId')) {
      logEvent(AnalyticEvent.CHANGE_COUNTRY, {
        from: prev.country,
        to: curr.country,
      })
    }

    if (isChanged('showAvailability')) {
      logEvent(AnalyticEvent.CHECK_FOR_AVAILABLE_STOCK, {
        content: !!curr.showAvailability,
      })
    }

    if (isChanged('phaseOutCheck')) {
      logEvent(AnalyticEvent.CHECK_FOR_PHASE_OUT, {
        content: !!curr.phaseOutCheck,
      })
    }

    if (isChanged('hideWithoutMarketClearance')) {
      logEvent(AnalyticEvent.HIDE_PRODUCTS_WITHOUT_MARKET_CLEARANCE, {
        content: !!curr.hideWithoutMarketClearance,
      })
    }

    if (isChanged('clearanceCheck')) {
      logEvent(AnalyticEvent.CHECK_FOR_CLEARANCE, {
        content: !!curr.clearanceCheck,
      })
    }

    if (isChanged('updatedCountryShareNotification')) {
      logEvent(AnalyticEvent.CHECK_FOR_CURATED_LISTS_UPDATES, {
        content: !!curr.updatedCountryShareNotification,
      })
    }

    if (isChanged('sharedProductListNotification')) {
      logEvent(AnalyticEvent.CHECK_FOR_SENT_PRODUCT_LISTS, {
        content: !!curr.sharedProductListNotification,
      })
    }

    if (isChanged('showPrices')) {
      logEvent(AnalyticEvent.CHECK_FOR_SHOW_PRICES, {
        content: !!curr.showPrices,
      })
    }

    // This should probably work for any route (something along "if after
    // updating user's profile, they don't have access to the route they're
    // currently on we need to redirect them to finder") but changing it now
    // requires Drager's approval...
    const routeName = router.currentRoute.value.name
    if (
      routeName === ROUTER_CONFIG.users.name &&
      !checkCanEnter(store.state, ROUTER_CONFIG.users.allowedRoles)
    ) {
      await router.push({ name: ROUTER_CONFIG.finder.name })
    }
  }

  return {
    user,
    currentCountry,
    isKeyUser,
    isAdmin,
    isKeyUserOrAdmin,
    isSalesChannelPartner,
    isBasicUser,
    isBasicPlusUser,
    isAdvancedUser,
    settingsLabel,
    role,
    currencyId,
    currency,
    currencyShortcut,
    separator,
    localeOfSeparator,
    checkCanEnter,
    canEnterUsersView,
    canEnterSystemView,
    getAvatarLabel,
    keyUserCountryRoles,
    keyUserCountries,
    allUserCountries,
    canShowPrices,
    userCanSeeGGP,
    userCanSeeGGPValue,
    isFirebaseConsentsGranted,
    showFirebaseConsentsPopup,
    isMultiProductsToMultiListsTutorialShowed,
    isListDetailsPinningTutorialShowed,
    isCuratedListDetailsPinningTutorialShowed,
    updateUser,
    userAvatarLabel,
  }
}

export default useUserHelper
