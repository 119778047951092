import { API } from '@/api'
import { initGoogleTagManager } from '@/googleTagManager.init'
import { startGoogleTagManager } from '@/googleTagManager.start'
import router from '@/routes'
import {
  initializeFirebaseAnalytics,
  setUserDataForAnalytics,
} from '@/services/firebase.service'
import { AnalyticEvent } from '@/types/analyticEvents'
import { logEvent } from './analytics.service'

export async function initUserTracking() {
  initGoogleTagManager()
}

export async function startUserTracking(
  user: API.DisplayUserProfileResponseDto,
) {
  await initializeFirebaseAnalytics()
  await setUserDataForAnalytics(user)
  logPageViewEvent()

  let first = true
  router.afterEach((to, from, failed) => {
    if (!failed && (first || to.path !== from.path)) {
      first = false
      logPageViewEvent()
    }
  })

  startGoogleTagManager()
}

export function getPageViewParams(search: string) {
  const cleanHash = search.includes('?')
    ? search.substring(search.indexOf('?') + 1) // remove first part of url form example
    : search.substring(2) // remove `#/` from URL

  const params = new URLSearchParams(cleanHash)
  params.delete('code')
  params.delete('id_token')

  return Object.fromEntries(params)
}

/**
 * Log 'page_view' event with params from URL (including UTM params)
 */
function logPageViewEvent() {
  logEvent(AnalyticEvent.PAGE_VIEW, getPageViewParams(location.search))
}
