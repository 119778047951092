import { API } from '@/api'
import { apiClient, errorHandler, handleUserRemoveError } from '@/services/data.service'
import { AppState } from '@/store/types'
import { ActionTree } from 'vuex'
import { UsersState } from './types'

export const actions: ActionTree<UsersState, AppState> = {
  async fetchInternalUsers({ commit }) {
    commit('updateIsInternalLoading', true)
    try {
      const { data } = await apiClient.users.getInternalUsersUsingGet()
      commit('updateUsers', data.content)
    } catch (e) {
      errorHandler(e)
    }
    commit('updateIsInternalLoading', false)
  },

  async fetchExternalUsers({ commit }) {
    commit('updateIsExternalLoading', true)
    try {
      const { data } = await apiClient.users.getExternalUsersUsingGet()
      commit('updateExternalUsers', data.content)
    } catch (e) {
      errorHandler(e)
    }
    commit('updateIsExternalLoading', false)
  },

  async fetchUser({ commit }, userId: number) {
    try {
      const { data } = await apiClient.users.getEditInfoUsingGet(userId)
      commit('updateUser', data)
    } catch (e) {
      errorHandler(e)
    }
  },

  async saveUpdatedUserCountryRole(
    { commit },
    payload: API.UserRoleResponseDto | API.UserRoleResponseDto[],
  ) {
    Array.isArray(payload)
      ? payload.forEach((x) => commit('updateUserCountryRole', x))
      : commit('updateUserCountryRole', payload)
  },

  async resetToBasicRoles(_, userId: number) {
    try {
      await apiClient.users.demoteAllUsingDelete(userId)
    } catch (e) {
      errorHandler(e)
    }
  },

  async assignToCountry(_, countryId: number) {
    try {
      await apiClient.users.assignCountryByIdUsingPatch(countryId)
    } catch (e) {
      errorHandler(e)
    }
  },

	async removeUser(_, userId: number) {
		try {
			await apiClient.users.deleteByUserIdUsingDelete(userId)
		} catch (e) {
			handleUserRemoveError(e)

			throw e
		}
	}
}
