import { useAppStore } from '@/store'
import { computed } from 'vue'

const useNotifications = () => {
  const store = useAppStore()
  const notifications = store.state.notifications

  store.dispatch('notifications/fetchUnreadCounter')

  const unreadCounter = computed(() => {
    return notifications?.unreadCounter ?? 0
  })

  return {
    unreadCounter,
  }
}

export default useNotifications
