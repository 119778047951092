import { useScroll } from '@vueuse/core'
import { watch } from 'vue'

export function isElementScrollable(
  el: HTMLElement | SVGElement | null | undefined,
): boolean {
  if (!el) {
    return false
  }
  const { overflowY } = getComputedStyle(el)

  return (
    ['auto', 'scroll'].includes(overflowY) && el.scrollHeight > el.clientHeight
  )
}

export function useBlockHoverOnScroll() {
  const body = document.body
  const { isScrolling } = useScroll(document)

  watch(isScrolling, (value) => {
    if (value) {
      body.classList.add('is-scrolling')
    } else {
      body.classList.remove('is-scrolling')
    }
  })
}
