import { t } from '@/i18n'
import {
  Option,
  OptionImpl,
  OptionRenderer,
  UseDebouncedSyncRefApi,
  WritableRefLike,
  groupBy,
  nestedRef,
  noop,
  refFromWritable,
  useDebouncedSyncRef,
} from '@/utils'
import { InjectionKey, Ref, computed, inject, provide } from 'vue'

export type FilterItem<Value = unknown> = FilterLeaf<Value> | FilterGroup<Value>

export type FilterGroup<Value = unknown> = {
  key?: undefined
  selectAllChildren?: { label: string }
  value?: unknown
  label: string
  children: FilterItem<Value>[]
}

export type FilterLeaf<Value = unknown> = Option<Value> & {
  children?: undefined
  selectAllChildren?: undefined
}

export type FilterKey = FilterLeaf['key']

export type FilterValue<Filter> = Filter extends FilterItem<infer Val>
  ? Val
  : never

export function FilterGroup<Value>({
  label,
  selectAllChildren,
  children,
}: Pick<
  FilterGroup<Value>,
  'label' | 'selectAllChildren' | 'children'
>): FilterGroup<Value> {
  return {
    label,
    selectAllChildren,
    children,
  }
}

export function LegacyFilterLeaf<T>(
  key: number | string,
  body: { label: string; value: T; renderer?: OptionRenderer },
): FilterLeaf<T> {
  return new OptionImpl(body.value, {
    getKey: () => key,
    getLabel: () => t(body.label),
    renderer: body.renderer,
  })
}

export function LegacyTranslatedFilterLeaf<T>(
  key: number | string,
  body: { label: string; value: T; renderer?: OptionRenderer },
): FilterLeaf<T> {
  return new OptionImpl(body.value, {
    getKey: () => key,
    getLabel: () => body.label,
    renderer: body.renderer,
  })
}

export type QueryFilter = {
  type: 'query'
}

export function QueryFilter(): QueryFilter {
  return { type: 'query' }
}

export function urlEncodeFilters(filters: readonly FilterKey[]) {
  return filters.length ? filters.join('-') : undefined
}

export function urlDecodeFilters<T>(
  item: FilterItem<T>,
  encoded: string | undefined,
) {
  const leafs = getLeafFilters(item)
  const decoded = (encoded || '').split('-').filter(Boolean)

  // all decoded keys are stringified so to match them back to leafs we first
  // group leafs by their stringified key. This means that when there are 2
  // leafs with different keys but the same encoded representation we'll match
  // both. For example:
  //
  // ```
  // leafs = [FilterLeaf(1, ...), FilterLeaf(2, ...), FilterLeaf('2', ...)]
  //                                         ^                   ^^^
  // encoded = '2'
  // result = [FilterLeaf(2, ...), FilterLeaf('2', ...)]
  // ```
  const byStrKey = groupBy(leafs, (leaf) => `${leaf.key}`)

  return decoded.flatMap((key) => {
    return byStrKey[key] || []
  })
}

export function getLeafFilters<T>(item: FilterItem<T>): FilterLeaf<T>[] {
  return item.children ? item.children.flatMap(getLeafFilters) : [item]
}

export function getLeafsByValues<T>(
  item: FilterItem<T>,
  values: readonly T[] | undefined,
): FilterLeaf<T>[] {
  return values?.length
    ? getLeafFilters(item).filter((leaf) => values.includes(leaf.value))
    : []
}

export type FiltersModel = {
  [Key in string]?: unknown
}

export type FiltersModelOf<T> = {
  [K in keyof T]?: T[K] extends FilterItem<infer V>
    ? V[]
    : T[K] extends QueryFilter
    ? string
    : never
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FiltersApi<T extends FiltersModel = any> =
  UseDebouncedSyncRefApi<T> & {
    inner: Ref<T>
    outer: Ref<T>
    isEmpty: Readonly<Ref<boolean>>
  }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FilterApi<Model = any> = {
  inner: Ref<Model | undefined>
  outer: Ref<Model | undefined>
  apply(): void
  clearAll(): void
}

export function useFiltersApi<T extends FiltersModel>(
  outerWritable: WritableRefLike<T>,
): FiltersApi<T> {
  const outer = refFromWritable(outerWritable)
  const [inner, api] = useDebouncedSyncRef(800, outer)

  const isEmpty = computed(() => {
    return Object.values(outer.value).every((val) => {
      return Array.isArray(val) ? !val.length : !val && val !== 0
    })
  })

  return { inner, outer, isEmpty, ...api }
}

const FILTER_API = Symbol() as InjectionKey<FilterApi>

export function provideFilterApi(
  rootApi: FiltersApi,
  key: () => string,
): FilterApi {
  const inner = nestedRef(rootApi.inner, key)

  const api: FilterApi = {
    inner,
    outer: nestedRef(rootApi.outer, key),
    apply() {
      rootApi.flush()
    },
    clearAll() {
      inner.value = undefined
      rootApi.flush()
    },
  }

  provide(FILTER_API, api)

  return api
}

export function provideSimpleFilterApi<Model>(model: Ref<Model | undefined>) {
  const api: FilterApi<Model> = {
    inner: model,
    outer: model,
    apply: noop,
    clearAll() {
      model.value = undefined
    },
  }

  provide(FILTER_API, api)

  return api
}

export function useFilterApi(required: false): FilterApi | undefined
export function useFilterApi(required?: true): FilterApi
export function useFilterApi(required = true) {
  const api = inject(FILTER_API, undefined)
  if (!api && required) {
    throw Error(`Filter API not provided`)
  }

  return api
}
