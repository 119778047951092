import { once } from '@/utils'
import { computedEager, tryOnScopeDispose, watchImmediate } from '@vueuse/core'
import {
  InjectionKey,
  MaybeRefOrGetter,
  Plugin,
  Ref,
  inject,
  nextTick,
  ref,
  toValue,
  watch,
} from 'vue'

type BlockScrollApi = {
  requestPageScrollBlocking: () => () => void
  isPageScrollBlocked: Readonly<Ref<boolean>>
}

const TOKEN = Symbol() as InjectionKey<BlockScrollApi>

type OnCleanup = Parameters<Parameters<typeof watchImmediate>[1]>[2]

export const BlockScroll: Plugin = (app) => {
  const pageScrollRequestCount = ref(0)
  const isPageScrollBlocked = computedEager(() => {
    return pageScrollRequestCount.value > 0
  })

  const api: BlockScrollApi = {
    requestPageScrollBlocking,
    isPageScrollBlocked,
  }

  app.provide(TOKEN, api)

  watchImmediate(isPageScrollBlocked, (block) => {
    document.body.classList.toggle('block-scroll', block)
  })

  tryOnScopeDispose(() => {
    document.body.classList.remove('block-scroll')
  })

  function requestPageScrollBlocking() {
    pageScrollRequestCount.value++

    return once(() => {
      return pageScrollRequestCount.value--
    })
  }
}

export function useBlockScroll(maybeShouldBlock?: MaybeRefOrGetter<unknown>) {
  const api = inject(TOKEN)!
  const blockPageScroll = ref(false)

  watch(blockPageScroll, onChange)
  if (maybeShouldBlock) {
    watchImmediate(() => toValue(maybeShouldBlock), onChange)
  }

  return { ...api, blockPageScroll }

  function onChange(cond: unknown, _: unknown, onCleanup: OnCleanup) {
    if (cond) {
      const off = api.requestPageScrollBlocking()
      onCleanup(() => {
        nextTick(off)
      })
    }
  }
}
