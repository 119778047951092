import { API } from '@/api'
import { computed, reactive, ref } from 'vue'

type ImageSize = 156 | 360 | 720 | 1080 | 2048

export const DEFAULT_IMAGE_SIZE = 1080

// eslint-disable-next-line @typescript-eslint/no-var-requires
const PLACEHOLDER_URL = require('@images/placeholder-small.svg')
const MEDIA_URL = 'https://draegermobilemedias.blob.core.windows.net'
const ASSETS_URL = 'https://product-assets.draeger.net'

export const getStaticImageUrl = (size: ImageSize, imageId: string) => {
  return `${MEDIA_URL}/${size}px/hca/${imageId}`
}

function get360ImageUrl(size: ImageSize, product: string, number: number) {
  return `${ASSETS_URL}/${size}px/hca/360degree/${product}/${number}.jpeg`
}

interface BaseImage {
  index: number
  imageId?: string
  category?: string
  hasError: () => boolean
  sources: {
    156: string
    720: string
    1080: string
  }
}

export interface ImageStatic extends BaseImage {
  type: 'static'
  imageId: string
}

export interface Image360 extends BaseImage {
  type: '360'
  sources360: {
    1080: string
  }
}

export interface ImagePlaceholder extends BaseImage {
  type: 'placeholder'
}

export type Image = ImageStatic | Image360 | ImagePlaceholder

type ImageSource = {
  src: string
  hasError: boolean
}

function ImageSource(url: string) {
  const hasError = ref(false)
  const img = new Image()
  img.src = url
  img.onerror = () => {
    img.onerror = null
    img.src = ''
    hasError.value = true
  }

  return [hasError, url] as const
}

function ImageSource360(urls: string[]) {
  const hasError = ref(false)

  const imagesAndUrlRefs = urls.map((srcUrl, index) => {
    const url = ref(srcUrl)
    const img = new Image()
    img.src = srcUrl
    img.onerror = () => onErrorAt(index)

    return [img, url] as const
  })

  function onErrorAt(index: number) {
    const [img, url] = imagesAndUrlRefs[index]
    img.onerror = null
    img.src = ''

    if (imagesAndUrlRefs.every(([img]) => !img.onerror)) {
      hasError.value = true
    } else {
      url.value = PLACEHOLDER_URL
    }
  }

  const combinedUrls = computed(() => {
    return imagesAndUrlRefs.map(([_, url]) => url.value).join(' ')
  })

  return [hasError, combinedUrls] as const
}

type Product = Pick<
  API.ExtendedProductResponseDto,
  'images' | 'imageId' | 'title' | 'image360'
>

function ImagePlaceholder(): Image {
  const [hasError, src] = ImageSource(PLACEHOLDER_URL)

  return {
    index: 0,
    type: 'placeholder',
    hasError: () => hasError.value,
    sources: { 156: src, 720: src, 1080: src },
  }
}

function ImageStatic(
  imageId: string | undefined,
  category: string | undefined,
): Image {
  if (imageId == null) return ImagePlaceholder()

  const [err156, src156] = ImageSource(getStaticImageUrl(156, imageId))
  const [err720, src720] = ImageSource(getStaticImageUrl(720, imageId))
  const [err1080, src1080] = ImageSource(getStaticImageUrl(1080, imageId))

  return reactive({
    index: 0,
    type: 'static',
    imageId,
    category,
    hasError: () => err156.value || err720.value || err1080.value,
    sources: {
      156: src156,
      720: src720,
      1080: src1080,
    },
  })
}

function Image360(product: string): Image360 {
  const urls1080 = Array.from({ length: 36 }, (_, idx) => {
    return get360ImageUrl(1080, product, idx + 1)
  })

  const [err156, src156] = ImageSource(get360ImageUrl(156, product, 1))
  const [err720, src720] = ImageSource(get360ImageUrl(720, product, 1))
  const [err1080, src1080] = ImageSource360(urls1080)

  return reactive({
    index: 0,
    type: '360',
    hasError: () => err156.value || err720.value || err1080.value,
    sources: {
      156: src156,
      720: src720,
      1080: ImageSource(get360ImageUrl(1080, product, 1))[1],
    },
    sources360: {
      1080: src1080,
    },
  })
}

export function getProductGalleryImages(product: Product) {
  const images: Image[] = []

  images.push(ImageStatic(product.imageId, 'default image'))

  if (product.image360) {
    images.push(Image360(product.title))
  }

  for (const image of product.images || []) {
    images.push(ImageStatic(image.imageId, image.imageCategory))
  }

  return images.map((img, index) => ({ ...img, index }))
}
