// @ts-nocheck

// https://developers.google.com/tag-platform/security/guides/consent?consentmode=basic&hl=pl#tag-manager

const GOOGLE_TAG_MANAGER_ID = process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID

let isGtmStarted = false
export const startGoogleTagManager = () => {
  if (!GOOGLE_TAG_MANAGER_ID || isGtmStarted) {
    return
  }

  isGtmStarted = true

  window.dataLayer = window.dataLayer || [];

  function gtag() {
    // eslint-disable-next-line prefer-rest-params
    dataLayer.push(arguments)
  }

  // Update consent states
  gtag('consent', 'update', {
    'analytics_storage': 'granted'
  });
}
