import { stripHTML } from '@/utils'
import { Directive } from 'vue'

const isHtmlElement = (el: unknown): el is HTMLElement => {
  return el instanceof HTMLElement
}

const stripHtmlDirective: Directive = (el, binding) => {
  if (!isHtmlElement(el) || typeof binding.value !== 'string') {
    return
  }

  el.innerHTML = stripHTML(binding.value)
}

export default stripHtmlDirective
