import { API } from '@/api'
import {
  FavoriteProduct,
  PreviousProductVariantAnalyticData,
} from '@/store/types'
import { MutationTree } from 'vuex'
import { ProductDetailsState } from './types'

export const mutations: MutationTree<ProductDetailsState> = {
  updateIsLoading(state, payload: boolean) {
    state.isLoading = payload
  },

  updateProductDetails(state, payload: API.ExtendedProductResponseDto) {
    state.product = payload
  },

  updatePreviousProductVariantAnalyticData(
    state,
    payload: PreviousProductVariantAnalyticData,
  ) {
    state.previousProductVariantAnalyticData = payload
  },

  updateProductFavorite(state, { id, isFavorite }: FavoriteProduct) {
    if (state.product?.id === id) {
      state.product.isFavorite = isFavorite
    }

    const otherProducts = [
      ...(state.alternativeProducts || []),
      ...(state.relatedProducts || []),
    ]

    for (const product of otherProducts) {
      if (product.id === id) {
        product.isFavorite = isFavorite
      }
    }
  },

  updateAlternativeProducts(state, payload: API.ProductRowV3ResponseDto[]) {
    state.alternativeProducts = payload
  },

  updateRelatedProducts(state, payload: API.ProductRowV3ResponseDto[]) {
    state.relatedProducts = payload
  },

  updateIsFavoriteDisabled(state, payload: boolean) {
    state.isFavoriteDisabled = payload
  },
}
