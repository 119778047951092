// @ts-nocheck

// https://developers.google.com/tag-platform/security/guides/consent?consentmode=basic&hl=pl#tag-manager

const GOOGLE_TAG_MANAGER_ID = process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID

let isGtmInitialized = false
export const initGoogleTagManager = () => {
  if (!GOOGLE_TAG_MANAGER_ID || isGtmInitialized) {
    return
  }

  isGtmInitialized = true

  window.dataLayer = window.dataLayer || [];

  function gtag() {
    // eslint-disable-next-line prefer-rest-params
    dataLayer.push(arguments)
  }

  // Set default consent states
  gtag('consent', 'default', {
    'ad_user_data': 'denied',
    'ad_personalization': 'denied',
    'ad_storage': 'denied',
    'analytics_storage': 'denied',
    'depersonalization': 'denied',
  })

  dataLayer.push({'gtm.start': new Date().getTime(), 'event': 'gtm.js'})

  // Load Tag Manager script
  const gtmScript = document.createElement('script');
  gtmScript.async = true;
  gtmScript.src = 'https://www.googletagmanager.com/gtm.js?id=' + GOOGLE_TAG_MANAGER_ID;

  const firstScript = document.getElementsByTagName('script')[0];
  firstScript.parentNode.insertBefore(gtmScript,firstScript);
}
