import { get, isLength, toPath } from 'lodash-es'

// note, that this regex handles only ASCII characters but real JS grammar
// accepts much wider range of characters
const IDENTIFIER_REGEX = /^[$a-z0-9_]*$/i

export type PathPart = string | number
export type PathParts = [string, ...PathPart[]]
export function splitPath(path: string): PathParts {
  if (!path) throw Error(`Unexpected empty path`)

  const [first, ...rest] = toPath(path).map((part) => {
    const asNum = part && +part

    return isLength(asNum) ? asNum : part
  })

  if (typeof first !== 'string' || !IDENTIFIER_REGEX.test(first)) {
    throw Error(`Path must start with an identifier. Got ${path}`)
  }

  return [first, ...rest]
}

export function getFromPath(obj: unknown, path: PathParts) {
  return get(obj, path)
}

export function joinPath(parts: PathPart[]): string {
  if (!parts.length) throw Error(`Unexpected empty path`)
  if (typeof parts[0] !== 'string' || !IDENTIFIER_REGEX.test(parts[0])) {
    throw Error(`Path must start with an identifier. Got ${parts}`)
  }

  const path = parts.reduce((path, part) => {
    if (part === '') {
      return `${path}[]`
    }

    if (typeof part === 'string' && IDENTIFIER_REGEX.test(part)) {
      return `${path}.${part}`
    }

    if (isNaN(+part)) {
      return `${path}[${JSON.stringify(part)}]`
    }

    return `${path}[${part}]`
  })

  return path as string
}

export function stripIndexes(path: string) {
  const parts = splitPath(path).map((p) => {
    return typeof p === 'number' ? '' : p
  })

  return joinPath(parts)
}
