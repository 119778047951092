import { Ref, computed, isRef, ref } from 'vue'

export type Source<Value> = Value | Readonly<Ref<Value>> | (() => Value)

export function refFromSource<T>(source: Source<T>): Readonly<Ref<T>> {
  if (isRef(source)) {
    return source
  }

  if (typeof source === 'function') {
    return computed(source as () => T)
  }

  return ref(source) as Readonly<Ref<T>>
}

export function getterFromSource<T>(source: Source<T>): () => T {
  if (isRef(source)) {
    return () => source.value
  }

  if (typeof source === 'function') {
    return source as () => T
  }

  return () => source
}
