import { getPersisted } from './persistenceHelper'
import { AppState } from './types'

export const state: AppState = {
  user: undefined,
  isLoading: false,
  isAutocompleteDataLoading: false,
  keyUserPermissions: undefined,
  showModal: {
    roleChange: false,
  },
  userListSortingType: 'NAME',
  autocompleteData: {
    searchValue: '',
    searchHints: [],
    searchHintsLoadTime: 0,
    totalCount: 0,
  },
  persisted: getPersisted(),
  sessionPersisted: getPersisted(true),
  onboardingNeeded: false,
}
