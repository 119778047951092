import { API } from '@/api'
import {
  ProductStatusSeverity,
  getColorFromProductStatusSeverity,
} from '@/domain/productStatus'
import { t } from '@/i18n'
import { Ref, computed, reactive } from 'vue'

type StatusBody = {
  title: string | (() => string)
  subtitle: string | (() => string)
  link?: string
}

function getterToRef<T>(value: T | (() => T)): T | Readonly<Ref<T>> {
  return typeof value === 'function' ? computed(value as () => T) : value
}

function Status<ID extends string>(
  id: ID,
  severity: ProductStatusSeverity,
  body: StatusBody,
) {
  return reactive({
    id,
    title: getterToRef(body.title),
    subtitle: getterToRef(body.subtitle),
    severity: severity,
    color: getColorFromProductStatusSeverity(severity),
    link: body.link,
  } as const)
}

export type CustomStatus = ReturnType<typeof CustomStatus>
export function CustomStatus(status: API.ProductCustomStatusResponseDto) {
  return Status('CustomStatus', status.status, {
    title: () => t(`productStatus.customStatusLabel.${status.status}`),
    subtitle: status.description || '',
    link: status.link,
  })
}

export type Available = typeof Available
export const Available = Status('AVAILABLE', 'AVAILABLE', {
  title: () => t('status.available'),
  subtitle: () => t('status.requestedProductIsAvailable'),
})

export type NotAvailablePhaseOut = typeof NotAvailablePhaseOut
export const NotAvailablePhaseOut = Status('NOT_AVAILABLE', 'NOT_AVAILABLE', {
  title: () => t('enums.productPhaseOutStatus.NOT_AVAILABLE'),
  subtitle: () => t('status.requestedProductIsNotAvailable'),
})

export type UpcomingPhaseOut = typeof UpcomingPhaseOut
export const UpcomingPhaseOut = Status('UPCOMING_PHASE_OUT', 'WARNING', {
  title: () => t('status.upcomingPhaseOut'),
  subtitle: () => t('status.productWillBePhasedOutSoon'),
})

export type PhasedOut = typeof PhasedOut
export const PhasedOut = Status('PHASED_OUT', 'NOT_AVAILABLE', {
  title: () => t('status.phaseOut'),
  subtitle: () => t('status.productHasBeenPhasedOut'),
})

export type ScpUpcomingPhaseOut = typeof ScpUpcomingPhaseOut
export const ScpUpcomingPhaseOut = Status('SCP_UPCOMING_PHASE_OUT', 'WARNING', {
  title: () => t('status.scpUpcomingPhaseOut'),
  subtitle: () => t('status.scpProductWillBePhasedOutSoon'),
})

export type ScpPhasedOut = typeof ScpPhasedOut
export const ScpPhasedOut = Status('SCP_PHASED_OUT', 'NOT_AVAILABLE', {
  title: () => t('status.scpPhaseOut'),
  subtitle: () => t('status.scpProductHasBeenPhasedOut'),
})

export type Blocked = typeof Blocked
export const Blocked = Status('BLOCKED', 'NOT_AVAILABLE', {
  title: () => t('status.blocked'),
  subtitle: () => t('status.pleaseReferToSalesInfo'),
})

export type LimitedQuantity = typeof LimitedQuantity
export const LimitedQuantity = Status('LIMITED_QUANTITY', 'WARNING', {
  title: () => t('status.limitedAvailability'),
  subtitle: () => t('status.limitedQuantity'),
})

export type ShipHold = typeof ShipHold
export const ShipHold = Status('SHIP_HOLD', 'NOT_AVAILABLE', {
  title: () => t('status.notAvailable'),
  subtitle: () => t('status.shipHold'),
})

export type OutOfStock = typeof OutOfStock
export const OutOfStock = Status('OUT_OF_STOCK', 'NOT_AVAILABLE', {
  title: () => t('status.notAvailable'),
  subtitle: () => t('status.outOfStock'),
})

// FIXME: update IDs of the following status when proper enum exists on the BE
export type MarketClearance = typeof MarketClearance
export const MarketClearance = Status('MarketClearance', 'NOT_AVAILABLE', {
  title: () => t('status.marketClearance'),
  subtitle: () => t('status.productNotAvailableInYourCountry'),
})

// FIXME: update IDs of the following status when proper enum exists on the BE
export type AbnormalQuantity = typeof AbnormalQuantity
export const AbnormalQuantity = Status('AbnormalQuantity', 'WARNING', {
  title: () => t('status.abnormalQuantity'),
  subtitle: () => t('status.yourRequestExceedsNormalQuantity'),
})

// FIXME: update IDs of the following status when proper enum exists on the BE
export type ContactDraeger = typeof ContactDraeger
export const ContactDraeger = Status('ContactDraeger', 'WARNING', {
  title: () => t('status.contactWith'),
  subtitle: () => t('status.contactWithDetails'),
})

export const STATUS_ORDER = [
  MarketClearance.id,
  'CustomStatus',
  PhasedOut.id,
	ScpPhasedOut.id,
  ShipHold.id,
  OutOfStock.id,
  NotAvailablePhaseOut.id,
  Blocked.id,
  LimitedQuantity.id,
  UpcomingPhaseOut.id,
	ScpUpcomingPhaseOut.id,
  AbnormalQuantity.id,
  Available.id,
  ContactDraeger.id,
] as const satisfies readonly SingleProductStatus['id'][]

export type SingleProductStatus =
  | MarketClearance
  | ReturnType<typeof CustomStatus>
  | NotAvailablePhaseOut
  | PhasedOut
	| ScpPhasedOut
  | ShipHold
  | OutOfStock
  | Blocked
  | LimitedQuantity
  | UpcomingPhaseOut
	| ScpUpcomingPhaseOut
  | AbnormalQuantity
  | Available
  | ContactDraeger
