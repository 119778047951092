import { PersistedState } from './types'

const KEY = 'draeger:persistedState'

export function setPersisted(value: PersistedState, session?: boolean) {
  const storage = session ? sessionStorage : localStorage
  if (value == null) {
    storage.removeItem(KEY)
  } else {
    storage.setItem(KEY, JSON.stringify(value))
  }
}

export function getPersisted(session?: boolean): PersistedState {
  const storage = session ? sessionStorage : localStorage
  const serialized = storage.getItem(KEY)

  return serialized ? JSON.parse(serialized) : undefined
}
