import { IS_TEST } from '@/flags'
import { AppState } from '@/store/types'
import { Store, useStore } from 'vuex'

export type AppStore = Store<AppState>
export const useAppStore = (): AppStore => useStore<AppState>()

export const vuexLogger =
  (prefix: string) =>
  (store: Store<unknown>): void => {
    if (IS_TEST) return

    /* eslint-disable no-console */
    store.subscribe((mutation) => {
      console.groupCollapsed(prefix, `mutation`, mutation.type)
      console.log(mutation.payload)
      console.groupEnd()
    })
    store.subscribeAction((action) => {
      console.groupCollapsed(prefix, `action`, action.type)
      console.log(action.payload)
      console.groupEnd()
    })
    /* eslint-enable no-console */
  }

export const getDynamicStore = async () => {
  const { default: store } = await import('@/store')

  return store
}
