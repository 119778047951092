import { API } from '@/api'

type ProductCustomStatus = API.ProductCustomStatusResponseDto

export type ProductStatusSeverity = (typeof ProductStatusSeverity)[number]
export const ProductStatusSeverity = [
  'AVAILABLE',
  'WARNING',
  'NOT_AVAILABLE',
] as const

export type ProductPhaseOutStatus = (typeof ProductPhaseOutStatus)[number]
export const ProductPhaseOutStatus = [
  'AVAILABLE',
  'UPCOMING_PHASE_OUT',
  'PHASED_OUT',
  'BLOCKED',
  'NOT_AVAILABLE',
] as const

export type ProductAvailabilityStatus =
  (typeof ProductAvailabilityStatus)[number]
export const ProductAvailabilityStatus = [
  'AVAILABLE',
  'SHIP_HOLD',
  'OUT_OF_STOCK',
  'LIMITED_QUANTITY',
] as const

export const ProductStatusSeverityMap = {
  AVAILABLE: 'AVAILABLE',
  BLOCKED: 'NOT_AVAILABLE',
  LIMITED_QUANTITY: 'WARNING',
  OUT_OF_STOCK: 'NOT_AVAILABLE',
  PHASED_OUT: 'NOT_AVAILABLE',
  SHIP_HOLD: 'NOT_AVAILABLE',
  UPCOMING_PHASE_OUT: 'WARNING',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
} as const satisfies Record<
  ProductPhaseOutStatus | ProductAvailabilityStatus,
  ProductStatusSeverity
>

export type ProductStatusColor = 'green' | 'yellow' | 'red'

export const getColorFromProductPhase = (
  status: API.ProductAvailabilityIndicatorDetailsResponseDto['phaseOutStatus'],
) => {
  if (status === 'UPCOMING_PHASE_OUT') return 'yellow'
  if (status === 'BLOCKED' || status === 'PHASED_OUT' || status === 'NOT_AVAILABLE') return 'red'

  return 'green'
}

export const getColorFromProductAvailability = (
  status: API.ProductAvailabilityIndicatorDetailsResponseDto['showAvailabilityStatus'],
) => {
  if (status === 'LIMITED_QUANTITY') return 'yellow'
  if (status === 'SHIP_HOLD' || status === 'OUT_OF_STOCK') return 'red'

  return 'green'
}

export function getColorFromProductStatusSeverity(
  light: ProductStatusSeverity,
): ProductStatusColor {
  if (light === 'WARNING') return 'yellow'
  if (light === 'NOT_AVAILABLE') return 'red'

  return 'green'
}

export function classifyCustomStatus(all: readonly ProductCustomStatus[]) {
  const live = all.filter((s) => !s.isEditMode)

  if (live.length === 1 && !live[0].countries?.length) {
    return { type: 'global', status: live[0] } as const
  }

  const countriesCount = live.reduce((sum, status) => {
    return sum + (status.countries?.length || 0)
  }, 0)

  if (!countriesCount) {
    return { type: 'empty' } as const
  }

  if (countriesCount === 1) {
    return { type: 'singleCountry', status: live[0], live } as const
  }

  return { type: 'multiCountries', count: countriesCount, live } as const
}
