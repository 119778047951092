import { FilterGroup, LegacyFilterLeaf, SearchFilters } from '@/store/types'
import { deviceGroup } from './devices.config'
import { productCluster } from './productCluster.config'

export const careArea = () => {
  return FilterGroup({
    label: 'search.careArea',
    children: [
      LegacyFilterLeaf(0, {
        value: 'OR',
        label: 'search.operatingRoom',
      }),
      LegacyFilterLeaf(1, {
        value: 'ICU',
        label: 'search.intensiveCareUnit',
      }),
      LegacyFilterLeaf(2, {
        value: 'NICU',
        label: 'search.neonatalIntensiveCareUnit',
      }),
    ],
  })
}

export const patientGroup = () => {
  return FilterGroup({
    label: 'search.patientGroup',
    children: [
      LegacyFilterLeaf(20, {
        value: 'ADULT',
        label: 'search.adults',
      }),
      LegacyFilterLeaf(21, {
        value: 'PEDIATRICS',
        label: 'search.pediatrics',
      }),
      LegacyFilterLeaf(22, {
        value: 'NEONATES',
        label: 'search.neonates',
      }),
    ],
  })
}

export const usageGroup = () => {
  return FilterGroup({
    label: 'search.usage',
    children: [
      LegacyFilterLeaf(40, {
        value: 'SINGLEUSE',
        label: 'enums.usage.SINGLEUSE',
      }),
      LegacyFilterLeaf(41, {
        value: 'REUSABLE',
        label: 'enums.usage.REUSABLE',
      }),
      LegacyFilterLeaf(42, {
        value: 'LIMITEDREUSABLE',
        label: 'enums.usage.LIMITEDREUSABLE',
      }),
      LegacyFilterLeaf(43, {
        value: 'SINGLEPATIENTMULTUSE',
        label: 'enums.usage.SINGLEPATIENTMULTUSE',
      }),
    ],
  })
}

export const moreFilters = () => {
  return FilterGroup({
    label: 'search.moreFilters',
    children: [
      LegacyFilterLeaf(50, {
        value: 'RFID',
        label: 'search.rfid',
      }),
      LegacyFilterLeaf(51, {
        value: 'LATEX_FREE',
        label: 'search.latexFree',
      }),
      LegacyFilterLeaf(52, {
        value: 'NEW',
        label: 'search.new',
      }),
    ],
  })
}

export const getDefaultSearchFilters = (): SearchFilters => ({
  careAreas: careArea(),
  deviceGroups: deviceGroup(),
  patientGroups: patientGroup(),
  usages: usageGroup(),
  chapterAreas: productCluster(),
  more: moreFilters(),
})
