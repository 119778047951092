import { ROUTER_CONFIG } from '@/routes/router.config'
import { ErrorCodes } from '@/types'
import { NavigationGuardNext, Router, RouterScrollBehavior } from 'vue-router'

export const navigateNextToErrors = (
  next: NavigationGuardNext,
  errorCode: ErrorCodes,
) => {
  next({
    ...ROUTER_CONFIG.errors,
    query: {
      code: errorCode,
    },
  })
}

export const findRouteByName = (routeName: string) => {
  return Object.values(ROUTER_CONFIG).find((route) => {
    return route.name === routeName
  })
}

export const goToExternalUsersList = async (router: Router) => {
  await router.push({
    name: ROUTER_CONFIG.users.name,
    query: { type: 'external' },
  })
}

export const returnToTopScrollBehavior =
  (
    noReturnRoutes: (typeof ROUTER_CONFIG)[keyof typeof ROUTER_CONFIG][],
    useSavedPosition = false,
  ): RouterScrollBehavior =>
  (to, _from, savedPosition) => {
    if (savedPosition && useSavedPosition) {
      return savedPosition
    }

    if (noReturnRoutes.some((route) => route.name === to.name)) {
      return false
    }

    return { top: 0 }
  }
