import { Composer, createI18n } from 'vue-i18n'
import * as yup from 'yup'
import { datetimeFormats, de, en, es, fr, it, ja, pt, tr, zh, id, ru } from './lang'

const messages = {
  en,
  de,
  es,
  fr,
  it,
  ja,
  pt,
  zh,
  tr,
  id,
  ru,
}

export const language = {
  en: 'en',
  de: 'de',
  es: 'es',
  fr: 'fr',
  it: 'it',
  ja: 'ja',
  pt: 'pt',
  zh: 'zh',
  tr: 'tr',
  id: 'id',
  ru: 'ru',
}

export const agglutinativeLanguagesList = ['DE', 'ES', 'FR', 'PT', 'TR']

export const defaultLocale: string = language.en

export const i18n = createI18n({
  legacy: false,
  allowComposition: true,
  globalInjection: true,
  locale: defaultLocale,
  fallbackLocale: 'en',
  datetimeFormats,
  messages,
})

export const { t, te, d, n } = i18n.global

initYupLocale(i18n.global as Composer)

export function initYupLocale({ t }: Composer) {
  yup.setLocale({
    mixed: {
      required: () => t('validation.required'),
      oneOf: (options: { values: unknown[] }) => {
        return t('validation.one_of', { options: options.values })
      },
      notType: ({ type, originalValue }) => {
        if (type === 'number' && originalValue === '') {
          return t('validation.required')
        }

        return t('validation.not_type')
      },
    },
    array: {
      min: ({ min }) => t('validation.array.min', { min }),
      max: ({ max }) => t('validation.array.max', { max }),
      length: ({ length }) => t('validation.array.length', { length }),
    },
    string: {
      email: () => t('validation.string.email'),
      min: ({ min }) => t('validation.string.min', { min }),
      max: ({ max }) => t('validation.string.max', { max }),
      length: ({ length }) => t('validation.string.length', { length }),
    },
    number: {
      min: ({ min }) => t('validation.number.min', { min }),
      moreThan: ({ more }) => t('validation.number.moreThan', { more }),
      max: ({ max }) => t('validation.number.max', { max }),
      lessThan: ({ less }) => t('validation.number.lessThan', { less }),
    },
  })
}
