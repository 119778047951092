import { API } from '@/api'
import { MutationTree } from 'vuex'
import { SortingType } from '../productListDetails.config'
import {
  DeleteProductsPayload,
  ManageConflictDialogPayload,
  MultipleSelectionPayload,
  ProductListDetailsState,
  UpdateProductListPayload,
} from './types'

export const mutations: MutationTree<ProductListDetailsState> = {
  updateProductListElements(
    state,
    payload: API.DisplayProductListElementV3ResponseDto[],
  ) {
    state.productListElements = payload
  },

  deleteFromProductListElements(state, payload: DeleteProductsPayload) {
    state.productListElements = state.productListElements.filter((element) => {
      return !payload.ids.includes(element.id)
    })
  },

  updateItemFromProductListElements(
    state,
    payload: Partial<API.DisplayProductListElementV3ResponseDto> &
      Required<Pick<API.DisplayProductListElementV3ResponseDto, 'id'>>,
  ) {
    const element = state.productListElements.find((element) => {
      return element.id === payload.id
    })

    if (!element) return
    const patch = { ...payload }

    if ('quantity' in patch && patch.pricing?.totalPrice) {
      patch.pricing.totalPrice = (patch.quantity || 0) * (element.pricing?.crp || 0)
    } else if ('crp' in patch && patch.pricing?.totalPrice) {
      patch.pricing.totalPrice = (element.quantity || 0) * (patch.pricing?.crp || 0)
    }

    state.selectedProduct = Object.assign(element, patch)
  },

  updateProductList(state, payload: UpdateProductListPayload) {
    state.productList = payload.productList
    state.productListType = payload.productListType
  },

  updateIsLoading(state, payload: boolean) {
    state.isLoading = payload
  },

  updateMoveToDialogVisible(state, payload: boolean) {
    state.isMoveToDialogVisible = payload
  },
  updateConflictDialog(state, payload: ManageConflictDialogPayload) {
    state.conflictsDialogData = payload
  },

  updateSelectedProduct(
    state,
    payload: API.DisplayProductListElementV3ResponseDto | undefined,
  ) {
    state.selectedProduct = payload
  },

  updateMultipleSelectedProducts(state, payload: MultipleSelectionPayload) {
    const selectedProductIndex = state.selectedProducts?.findIndex(
      (product: API.DisplayProductListElementV3ResponseDto) =>
        product.productId === payload.product.productId,
    )
    if (!selectedProductIndex && selectedProductIndex !== 0) return
    if (payload.isSelected && selectedProductIndex === -1) {
      state.selectedProducts?.push(payload.product)
    } else if (!payload.isSelected) {
      state.selectedProducts?.splice(selectedProductIndex, 1)
    }
  },

  clearMultipleSelectedProducts(state) {
    state.selectedProducts = []
  },

  updateIsSelectMode(state, payload: boolean) {
    state.isSelectMode = payload
  },

  updateListSortingType(state, payload: SortingType) {
    state.listSortingType = payload
  },

  updateIsOrderEditionMode(state, payload: boolean) {
    state.isOrderEditionMode = payload
  },

  setSortingList(state) {
    const list = state.productListElements
    if (!state.productListElementsWithSorting?.customSort) {
      list.forEach((i, index) => (i.sortOrder = index))
    }
    state.orderEditionListElements = list
  },

  updateOrderEditionListElements(
    state,
    payload: { onListId: number; order: number },
  ) {
    const item = state.orderEditionListElements?.find(
      (i) => i.id === payload.onListId,
    )
    if (!item) return
    item.sortOrder = payload.order
  },

  updateProductListElementsWithSorting(
    state,
    payload: API.ListElementsDetailsResponseDto,
  ) {
    state.productListElementsWithSorting = payload
  },
}
