/* eslint-disable no-console */
import { createApi } from '@/services/api.factory'
import {
  ACCESS_DENIED_ERROR_CODE,
  ERROR_CODES,
  ERROR_SOURCE,
  LOGOUT_PAGE,
  OKTA_LOGIN_PAGE,
  PASSWORD_RESET_PAGE,
  PASSWORD_RESET_PREFIX_CODE,
} from './config/api.config'
import * as ToastsService from './toast.service'

import { API } from '@/api'
import PasswordResetRedirectError from '@/helpers/errors/PasswordResetRedirectError'
import { rejectedScpStatus } from '@/helpers/scpStatuses.utils'
import { i18n, te } from '@/i18n'
import router from '@/routes'
import { ShareDialogType } from '@/routes/productListDetails/ShareDialog/shareDialog.config'
import { ROUTER_CONFIG } from '@/routes/router.config'
import { getDynamicStore } from '@/store'
import { ApiErrorResponse, BusinessError } from '@/types'
import { AnalyticEvent } from '@/types/analyticEvents'
import { UseAsyncStateReturn, useAsyncState } from '@vueuse/core'
import { logEvent } from './analytics.service'
import { getCookie, saveCookie } from '@/services/auth-token.service'
import { IS_DEV } from '@/flags'

type SharedProductList = {
  listId: number
  listName: string
  shareType: ShareDialogType
}

enum SharedProductListSuccessMessage {
  INDIVIDUAL = 'productLists.sharingIndividualSuccess',
  SCP_USER = 'productLists.sharingIndividualSuccess',
  COUNTRY = 'productLists.sharingCountrySuccess',
}

const RESULT_KEYS = {
  SALES_CHANNEL_PARTNER_USER_REJECTED: 'SALES_CHANNEL_PARTNER_USER_REJECTED',
  SALES_CHANNEL_PARTNER_USER_PENDING: 'SALES_CHANNEL_PARTNER_USER_PENDING',
}

const changeScpUserStatus = async (
  scpStatus: API.DisplayUserProfileResponseDto['scpStatus'],
) => {
  const store = await getDynamicStore()
  await store.dispatch('setScpUserStatus', scpStatus)
}

export const checkIsPasswordReset = (params: URLSearchParams) => {
  const error = params.get('error')
  const errorDescription = params.get('error_description')

  return (
    error &&
    errorDescription &&
    error === ACCESS_DENIED_ERROR_CODE &&
    errorDescription.startsWith(PASSWORD_RESET_PREFIX_CODE)
  )
}

// @ts-ignore
const t = i18n.global.t
export const apiClient = createApi()

export const goToPasswordReset = () => {
  window.location.replace(PASSWORD_RESET_PAGE)
}

export const goToLoginPage = async () => {
  window.location.replace(OKTA_LOGIN_PAGE)
}

export const goToLogoutPage = () => {
  window.location.replace(LOGOUT_PAGE)
}

export const handleLoginFromToken = async (params: string) => {
  if (!params) {
    return
  }
  const urlSearchParams = new URLSearchParams(params.substring(2)) // remove `#/` from URL

  if (checkIsPasswordReset(urlSearchParams)) {
    goToPasswordReset()

    // throwing error to stop fetching initial data
    throw new PasswordResetRedirectError()
  }

  const code = urlSearchParams.get('code')

  if (!code) {
    return
  }

  // Check if third-party cookies are enabled
  let hasCookiesEnabled = false
  const authToken = getCookie('authorization')

  if (authToken && authToken !== null && authToken !== undefined) {
    hasCookiesEnabled = true
  }

  const result = await apiClient.login.loginUsingPost(
    {
      code: code,
      hasCookiesEnabled: hasCookiesEnabled,
      redirectUrl: window.location.origin + '/',
    },
    {
      headers: {
        referer: window.location.origin + '/',
      },
    },
  )

  return result
}

const isUnauthorized = (error: Response) => {
  return error.status === ERROR_CODES.UNAUTHORIZED
}

const isForbidden = (error: Response) => {
  return error.status === ERROR_CODES.FORBIDDEN
}

const isRejectedSCP = (error: ApiErrorResponse) => {
  const bError = error.error as BusinessError

  return bError.resultKey === RESULT_KEYS.SALES_CHANNEL_PARTNER_USER_REJECTED
}
const isNotFound = (error: Response) => {
  return error.status === ERROR_CODES.NOT_FOUND
}

const isResourceNotFound = (error: Response) => {
  return error.status === ERROR_CODES.RESOURCE_NOT_FOUND
}

export const isBusinessError = (error: Response) => {
  return error.status === ERROR_CODES.BUSINESS_ERROR
}

const isProductListNameTaken = (error: Response) => {
  return error.status === ERROR_CODES.VALIDATION_ERROR
}

const isServerError = (error: Response) => {
  return error.status === ERROR_CODES.INTERNAL_SERVER_ERROR
}

const isOptimisticLockingError = (error: Response) => {
  return error.status === ERROR_CODES.OPTIMISTIC_LOCKING
}

const isPendingUserError = (error: ApiErrorResponse) => {
  const bError = error.error as BusinessError

  return bError.resultKey === RESULT_KEYS.SALES_CHANNEL_PARTNER_USER_PENDING
}

const handleUnauthorized = async () => {
  if (!IS_DEV) {
    goToLoginPage()
  }
}

const getNetworkErrorToast = (errorResponse: ApiErrorResponse) => {
  const baseError = ('error' in errorResponse && errorResponse.error) ?? ''
  const detail = JSON.stringify(baseError, null, 2)
  const summary = `${t('common.apiError')}: ${errorResponse.status} ${
    errorResponse.statusText
  }`

  return {
    summary,
    detail,
  }
}

const getGenericErrorToast = (error: Error) => {
  return {
    summary: `${t('common.error')}: ${error.name}`,
    detail: `${error.message};\n${error.stack}`,
  }
}

export const errorHandler = async (
  error: Error | ApiErrorResponse | unknown,
) => {
  const asError = error as Error
  const asApiErrorResponse = error as ApiErrorResponse
  if ('status' in asApiErrorResponse && isUnauthorized(asApiErrorResponse)) {
    handleUnauthorized()

    return
  }
  const store = getDynamicStore()

  if ('status' in asApiErrorResponse && isForbidden(asApiErrorResponse)) {
    if (isPendingUserError(asApiErrorResponse)) {
      if ((await store).state.user?.countryId) {
        await router.push({
          name: ROUTER_CONFIG.scpRegistrationWaitingRoom.name,
        })

        return
      } else {
        await router.push({ name: ROUTER_CONFIG.scpRegistration.name })

        return
      }

      return
    } else if (isRejectedSCP(asApiErrorResponse)) {
      await changeScpUserStatus(rejectedScpStatus)
      await router.push({ name: ROUTER_CONFIG.scpRegistrationRejected.name })

      return // return to don't show Toast with error
    } else {
      await router.push({
        name: ROUTER_CONFIG.errors.name,
        query: { code: 403 },
      })
    }
  }

  if (
    'status' in asApiErrorResponse &&
    isResourceNotFound(asApiErrorResponse)
  ) {
    await router.push({ name: ROUTER_CONFIG.errors.name, query: { code: 464 } })
  }

  if ('status' in asApiErrorResponse && isNotFound(asApiErrorResponse)) {
    await router.push({ name: ROUTER_CONFIG.errors.name, query: { code: 404 } })
  }

  if (
    'status' in asApiErrorResponse &&
    isOptimisticLockingError(asApiErrorResponse)
  ) {
    return
  }

  if ('status' in asApiErrorResponse && isServerError(asApiErrorResponse)) {
    await router.push({ name: ROUTER_CONFIG.errors.name, query: { code: 500 } })
  }

  if (!navigator.onLine) {
    await router.push({
      name: ROUTER_CONFIG.errors.name,
      query: { code: 'no-internet' },
    })

    return
  } else {
    const toast =
      'url' in asApiErrorResponse && 'error' in asApiErrorResponse
        ? getNetworkErrorToast(asApiErrorResponse)
        : getGenericErrorToast(asError)

    ToastsService.showToast(toast)
  }
}

export const loginErrorHandler = (
  error: Error | PasswordResetRedirectError | ApiErrorResponse | unknown,
) => {
  const asApiErrorResponse = error as ApiErrorResponse

  if ('status' in asApiErrorResponse && isBusinessError(asApiErrorResponse)) {
    const bError = asApiErrorResponse.error as BusinessError

    if (
      (bError.resultKey === 'USER_ACCOUNT_DISABLED' ||
        bError.resultKey === 'INVALID_AUTH_LEVEL') &&
      bError.errorDetails.length
    ) {
      saveCookie('isBlockedAccount_', true)
      saveCookie('jwt_', bError.errorDetails[0])
    }

    router.push({
      name: ROUTER_CONFIG.loginError.name,
      query: { code: bError.code, resultKey: bError.resultKey },
    })

    return
  }

  if (error instanceof PasswordResetRedirectError) {
    return
  }

  errorHandler(error)
}

export const saveAsMineSuccessHandler = (listName: string) => {
  const summary = t('productList.saveAsMineSuccess', {
    listName: `<strong>${listName}</strong>`,
  })
  const toast = {
    summary,
    severity: 'success',
  }
  ToastsService.showToast(toast)
}

export const sharingSuccessHandler = (payload: SharedProductList) => {
  const summary = t(SharedProductListSuccessMessage[payload.shareType], {
    listName: `<strong>${payload.listName}</strong>`,
  })

  ToastsService.showToast({
    summary,
    severity: 'success',
  })

  logEvent(AnalyticEvent.SHARE_PRODUCT_LIST_COMPLETED, {
    content: payload.listId,
    share_type: payload.shareType,
  })
}

export const productListErrorHander = async (
  error: Error | ApiErrorResponse | unknown,
) => {
  const asApiErrorResponse = error as ApiErrorResponse
  if (
    'status' in asApiErrorResponse &&
    (isProductListNameTaken(asApiErrorResponse) ||
      isOptimisticLockingError(asApiErrorResponse))
  ) {
    const toast = {
      summary: t('error.INVALID_UNIQUE_VALUE'),
    }
    ToastsService.showToast(toast)
  } else {
    errorHandler(error)
  }
}

export const draftErrorHandler = async (
  error: Error | ApiErrorResponse | unknown,
) => {
  const asApiErrorResponse = error as ApiErrorResponse
  if ('status' in asApiErrorResponse && asApiErrorResponse.status === 409) {
    const toast = {
      summary: t('error.DRAFT_TAKEN'),
    }
    ToastsService.showToast(toast)
  } else if (
    'status' in asApiErrorResponse &&
    isForbidden(asApiErrorResponse)
  ) {
    router.push({
      name: ROUTER_CONFIG.errors.name,
      query: { code: 403, source: ERROR_SOURCE.DRAFT },
    })
  } else {
    errorHandler(error)
  }
}

export const shareDraftErrorHandler = async (
  error: Error | ApiErrorResponse | unknown,
) => {
  const asApiErrorResponse = error as ApiErrorResponse
  if ('status' in asApiErrorResponse && asApiErrorResponse.status === 403) {
    const toast = {
      summary: t('error.INSUFFICIENT_PERMISSIONS'),
    }
    ToastsService.showToast(toast)
  } else {
    errorHandler(error)
  }
}

export const countrySharingErrorHandler = async (
  error: Error | ApiErrorResponse | unknown,
) => {
  const asApiErrorResponse = error as ApiErrorResponse

  if ('status' in asApiErrorResponse && isForbidden(asApiErrorResponse)) {
    router.push({
      name: ROUTER_CONFIG.errors.name,
      query: { code: 403, source: ERROR_SOURCE.DRAFT },
    })
  } else {
    errorHandler(error)
  }
}

export const handleSynchronizeResponse = async (success: boolean) => {
  const toast = success
    ? {
        summary: t('system.synchronizeSuccessTitle'),
        detail: t('system.synchronizeSuccessBody'),
        severity: 'success',
      }
    : {
        summary: t('system.synchronizeFailedTitle'),
        detail: t('system.synchronizeFailedBody'),
        severity: 'warn',
      }

  ToastsService.showToast(toast)
}

export const handleUpdateCountriesIsAvailableForScpError = async (
  error: Error | ApiErrorResponse | unknown,
) => {
  const asApiErrorResponse = error as ApiErrorResponse

  if ('status' in asApiErrorResponse && isBusinessError(asApiErrorResponse)) {
    const toast = {
      summary: t('error.SCP_USER_ASSIGNED_TO_COUNTRY'),
    }
    ToastsService.showToast(toast)
  } else {
    errorHandler(error)
  }
}

export const showToastAfterSavingCustomOrder = () => {
  const summary = t('productList.customOrderSaved', {
    customOrder: `<strong>${t('productList.customOrder')}</strong>`,
  })
  const toast = {
    summary,
    severity: 'success',
  }
  ToastsService.showToast(toast)
}

export const handleUserRemoveError = async (
  error: Error | ApiErrorResponse | unknown,
) => {
  const asApiErrorResponse = error as ApiErrorResponse

  if ('status' in asApiErrorResponse && isBusinessError(asApiErrorResponse)) {
    const toast = {
      summary: t('users.removeError.USER_ADMIN_CANNOT_BE_DELETED.title'),
      detail: t('users.removeError.USER_ADMIN_CANNOT_BE_DELETED.description'),
    }
    ToastsService.showToast(toast)
  } else {
    errorHandler(error)
  }
}

/* eslint-enable no-console */

export function useLazyAsyncState<Data, Params extends unknown[] = []>(
  getDataAsync: (...args: Params) => Promise<Data>,
): UseAsyncStateReturn<Data | undefined, Params, true> {
  return useAsyncState(getDataAsync, undefined, {
    immediate: false,
    resetOnExecute: false,
    onError: (e) => errorHandler(e),
  })
}

export const handleManageAvailabilityError = async (
  error: Error | ApiErrorResponse | unknown,
) => {
  const asApiErrorResponse = error as ApiErrorResponse

  if ('status' in asApiErrorResponse && isBusinessError(asApiErrorResponse)) {
    const resultKey = (asApiErrorResponse.error as BusinessError).resultKey
    const errorTranslationKey = `manageAvailability.errors.${resultKey}.title`

    const summary = te(errorTranslationKey)
      ? t(errorTranslationKey)
      : t('manageAvailability.errors.unknown')

    const toast = {
      summary,
    }
    ToastsService.showToast(toast)
  } else {
    errorHandler(error)
  }
}
