import { ProductListDetailsState } from './types'

export const state: ProductListDetailsState = {
  productList: undefined,
  productListType: undefined,
  productListElements: [],
  isLoading: false,
  selectedProduct: undefined,
  selectedProducts: [],
  isMoveToDialogVisible: false,
  conflictsDialogData: {
    isConflictDialogVisible: false,
    targetListId: undefined,
  },
  isSelectMode: false,
  listSortingType: 'CREATED_AT',
  isOrderEditionMode: false,
}
