import { i18n } from '@/i18n'
import { Source, has, refFromSource } from '@/utils'
import { Component, Ref, computed, reactive } from 'vue'

export type OptionRenderer = {
  readonly component: Component
  readonly props?: Record<string, unknown>
}

type OptionCfg<Value> = {
  readonly getKey: (val: Value) => string | number
  readonly getLabel: (val: Value) => string
  readonly renderer?: OptionRenderer
}

export type Option<Value = unknown> = {
  readonly key: string | number
  readonly value: Value
  readonly label: string
  readonly renderer?: OptionRenderer
}

type OptionBuilder<BaseValue> = OptionCfg<BaseValue> & {
  <Value extends BaseValue>(value: Value): Option<Value>
}

/**
 * Useful in components that allow selection of option(s) from set of available
 * options. Contains unique `key`, `label` for easy rendering and `value` that
 * holds arbitrary data (preferably enum value or DTO). This must be a class
 * because otherwise vee-validate would try to deep merge it and throw on label
 * getter.
 */
export class OptionImpl<Value = unknown> implements Option<Value> {
  readonly key!: string | number
  readonly value!: Value

  constructor(value: Value, private cfg: OptionCfg<Value>) {
    this.key = cfg.getKey(value)
    this.value = value
  }

  get renderer() {
    return this.cfg.renderer
  }

  get label() {
    return this.cfg.getLabel(this.value)
  }
}

export function OptionBuilder<Base>(cfg: OptionCfg<Base>): OptionBuilder<Base> {
  return Object.assign(builder, {
    getKey: cfg.getKey,
    getLabel: cfg.getLabel,
    renderer: cfg.renderer,
  })

  function builder<Value extends Base>(value: Value): Option<Value> {
    return new OptionImpl(value, cfg)
  }
}

type UseEnumOptionsCfg<Value extends string> = {
  readonly enum: Readonly<Record<string, Value>> | readonly Value[]
  readonly name: string
  /**
   * Archived options won't be present in EnumOptions#options
   */
  readonly archived?: Source<Value[]>
  readonly renderer?: Option<Value>['renderer']
}

export type OptionsStore<Value> = {
  readonly values: readonly Value[]
  readonly options: Option<Value>[]

  fromValue(value: Value): Option<Value>
  fromValue(value: Value | undefined | null): Option<Value> | undefined

  wrapModelForSingleSelect(
    current: Ref<Value | undefined>,
  ): Ref<Option<Value> | undefined>

  wrapModelForMultiSelect(
    current: Ref<Value[] | undefined>,
  ): Ref<Option<Value>[] | undefined>
}

/**
 * Given an enum object and its name returns an object that contains:
 * * `values` - a list of all known enum values
 * * `options` - a list of enum values mapped to option objects. Option object
 *   is an object that has `value` - enum value, `label` - already translated
 *   string and unique `key` useful in v-for
 * * `wrapModelForSingleSelect` - an util function that given a ref of enum
 *   value or undefined returns a new writable ref of option object associated
 *   with the source value or undefined. The refs will be kept in sync
 * * `wrapModelForMultiSelect` - similar to wrapModelForSingleSelect but maps
 *   array of enum values or undefined to array of option objects or undefined
 */
export function defineEnumOptions<Value extends string>(
  cfg: UseEnumOptionsCfg<Value>,
): OptionsStore<Value> {
  return defineOptionsStore({
    values: Object.values(cfg.enum) as Value[],
    archived: cfg.archived,
    builder: OptionBuilder({
      getKey: (value) => value,
      getLabel: (value) => i18n.global.t(`enums.${cfg.name}.${value}`),
      renderer: cfg.renderer,
    }),
  })
}

type DefineOptionsStoreCfg<Value> = {
  readonly values: Source<readonly Value[]>
  /**
   * Archived options won't be present in Options#options
   */
  readonly archived?: Source<readonly Value[]>
  readonly builder: OptionBuilder<Value>
}

export function defineOptionsStore<Value>(
  cfg: DefineOptionsStoreCfg<Value>,
): OptionsStore<Value> {
  const values = refFromSource(cfg.values)
  const archived = refFromSource(cfg.archived || [])

  const options = computed(() => {
    return values.value
      .filter((v) => !has(archived.value, v, valueEquals))
      .map(cfg.builder)
  })

  return reactive({
    values,
    options,
    fromValue,
    wrapModelForSingleSelect,
    wrapModelForMultiSelect,
  })

  function valueEquals(a: Value, b: Value) {
    return cfg.builder.getKey(a) === cfg.builder.getKey(b)
  }

  function wrapModelForSingleSelect(current: Ref<Value | undefined>) {
    return computed({
      get() {
        return current.value && fromValue(current.value)
      },
      set(opt) {
        current.value = opt?.value
      },
    })
  }

  function wrapModelForMultiSelect(current: Ref<Value[] | undefined>) {
    return computed({
      get() {
        return current.value?.map((val) => fromValue(val))
      },
      set(opts) {
        current.value = opts?.map((opt) => opt.value)
      },
    })
  }

  function fromValue(value: Value): Option<Value>
  function fromValue(value: Value | null | undefined): Option<Value> | undefined
  function fromValue(
    value: Value | null | undefined,
  ): Option<Value> | undefined {
    if (!value) return

    const key = cfg.builder.getKey(value)
    const opt = options.value.find((opt) => opt.key === key)

    // `|| cfg.builder(value)` is here for situations like some enum value is
    // removed from FE code but some old row in DB still uses it
    return opt || cfg.builder(value as Value)
  }
}

export function isOption(maybeOpt: unknown): maybeOpt is Option<unknown> {
  return !!maybeOpt && typeof maybeOpt === 'object' && 'value' in maybeOpt
}

export function isOptionOf<T>(maybeOpt: unknown, values: T | readonly T[]) {
  if (!isOption(maybeOpt)) return false

  return Array.isArray(values)
    ? has(values, maybeOpt.value)
    : values === maybeOpt.value
}
