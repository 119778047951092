import { API } from '@/api'
import { MutationTree } from 'vuex'
import { StaticDataState } from './types'

export const mutations: MutationTree<StaticDataState> = {
  updateCountriesList(state, payload: API.CountryDto[]) {
    state.countriesList = payload
  },

  updateSubregionsList(state, payload: API.SubregionDto[]) {
    state.subregionsList = payload
  },

  updateCurrencyExchangesList(state, payload: API.CurrencyExchangeDto[]) {
    state.currencyExchangeList = payload
  },

  updateOrganizationRolesList(
    state,
    payload: API.OrganizationRoleListResponseDto['organizationRoles'],
  ) {
    state.organizationRolesList = payload
  },

  updateIsLoading(state, payload: boolean) {
    state.isLoading = payload
  },

  updateAppInfo(state, payload: API.AppInfoResponseDto) {
    state.appInfo = payload
  },

  updateLanguagesList(state, payload: API.LanguageResponseDto[]) {
    state.languagesList = payload
  },
}
