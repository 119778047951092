<template>
  <div class="app-card">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.app-card {
  position: relative;
  padding: $card-padding;
  border-radius: 6px;
  background-color: $odx-white;
  transition: all 0.25s ease;
}
</style>
