import { API } from '@/api'
import { MutationTree } from 'vuex'
import { ProductListsState, SortingType } from './types'

export const mutations: MutationTree<ProductListsState> = {
  updateProductLists(
    state,
    payload?: API.ResultListDtoOfProductListRowResponseDto,
  ) {
    state.productLists = payload?.content ?? []
  },

  updateSharedProductLists(
    state,
    payload?: API.ResultListDtoOfCountryShareDto,
  ) {
    state.sharedProductLists = payload?.content ?? []
  },

  updatePinnedProductLists(
    state,
    payload?: API.ResultListDtoOfPinnedListResponseDto,
  ) {
    state.pinnedProductLists = payload?.content ?? []
  },

  updateDraftProductLists(
    state,
    payload?: API.ResultListDtoOfShareDraftV3ResponseDto,
  ) {
    state.draftProductLists = payload?.content ?? []
  },

  updateIsLoadingProductLists(state, payload: boolean) {
    state.isLoadingProductLists = payload
  },

  updateListSortingType(state, payload: SortingType) {
    state.listSortingType = payload
  },

  updateisEditMode(state, payload: boolean) {
    state.isEditMode = payload
  },
}
