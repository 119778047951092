import { API } from '@/api'
import { MutationTree } from 'vuex'
import { SystemState } from './types'
import { formatDateTime } from '../../../services/formatter.service'
import { stringFormatDateTime } from '@/utils'

export const mutations = {
  updateAreImportStatusesLoading(state, payload: boolean) {
    state.areImportStatusesLoading = payload
  },
  updateIsImportsHistoryLoading(state, payload: boolean) {
    state.isImportsHistoryLoading = payload
  },
  updateImportStatuses(
    state,
    payload: API.ResultListDtoOfTableImportStatusResponseDto,
  ) {
    state.importStatuses = payload.content
  },
  updateImportsHistory(
    state,
    payload: API.ResultListDtoOfTableImportStatusDetailsResponseDto,
  ) {
    state.importsHistory = payload.content.map((item) => ({
      id: item.id,
      status: item.status,
      errors: item.errors,
      startDatetime: item.startDatetime ? formatDateTime(
        stringFormatDateTime,
        new Date(item.startDatetime).getTime(),
      ) : undefined,
			finishDatetime: item.finishDatetime ? formatDateTime(
				stringFormatDateTime,
				new Date(item.finishDatetime).getTime(),
			) : undefined,
    }))
  },
  pendingSynchronize(state, payload: boolean) {
    state.pendingSynchronize = payload
  },
	updateSynchronizationStatus(state, payload: API.TableImportStatusSynchronizationResponseDto) {
		state.synchronizationStatus = payload
	}
} as const satisfies MutationTree<SystemState>
