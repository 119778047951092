import { i18n, t, te } from '@/i18n'
import { useAppStore } from '@/store'
import { computed } from 'vue'
import {
  CountryForListItem,
  CurrencyExchangeListItem,
  OrganizationRoleListItem,
} from './types'

const UNKNOWN_CURRENCY_NAME = 'Unknown currency'

const useStaticData = () => {
  const store = useAppStore()

  const currencyExchangeList = computed(() => {
    return store.state.staticData?.currencyExchangeList ?? []
  })

  const countriesList = computed(() => {
    return store.state.staticData?.countriesList ?? []
  })

  const organizationRolesList = computed(() => {
    return store.state.staticData?.organizationRolesList ?? []
  })

  const languagesList = computed(() => {
    return store.state.staticData?.languagesList ?? []
  })

  const countriesForList = computed<CountryForListItem[]>(() => {
    return countriesList.value.map((item) => {
      return {
        ...item,
        name: item.country,
      }
    })
  })

  const refreshCountriesForList = async () => {
    return await store.dispatch('staticData/fetchCountriesList')
  }

  const organizationRolesForList = computed<OrganizationRoleListItem[]>(() =>
    organizationRolesList.value.map((item) => {
      return {
        name: i18n.global.t(`enums.organizationRole.${item}`),
        value: item,
      }
    }),
  )

  const isAppInfo = computed(() => {
    return !!store.state.staticData?.appInfo
  })

  const privacyStatement = computed(() => {
    return store.state.staticData?.appInfo?.privacyPolicyWeb ?? ''
  })

  const legalNotices = computed(() => {
    return store.state.staticData?.appInfo?.legalNotices ?? ''
  })

  const providerIdentification = computed(() => {
    return store.state.staticData?.appInfo?.providerIdentification ?? ''
  })

  const instructionForUse = computed(() => {
    return store.state.staticData?.appInfo?.instructionForUseWeb ?? ''
  })

  const currencyTranslationKeyBase = `currency`

  const currencyExchangeForList = computed<CurrencyExchangeListItem[]>(() =>
    currencyExchangeList.value.map((item) => {
      const translationKey = `${currencyTranslationKeyBase}.${item.title}`
      const doesCurrencyTranslationExist = te(translationKey)

      return {
        ...item,
        name: doesCurrencyTranslationExist
          ? t(translationKey)
          : item.currencyName ?? item.title ?? UNKNOWN_CURRENCY_NAME,
      }
    }),
  )
  const languagesForList = computed(() =>
    languagesList.value.map((item) => {
      return {
        ...item,
        name: item.name,
      }
    }),
  )

  const getCountryFromShortcut = (countryShortcut: string | undefined) => {
    return countriesList.value.find((country) => {
      return country.title === countryShortcut
    })
  }

  const getCountryFullName = (countryShortcut: string | undefined) => {
    if (!countryShortcut) {
      return ''
    }

    return getCountryFromShortcut(countryShortcut)?.country || ''
  }

  const getCountryForListFromShortcut = (
    countryShortcut: string | undefined,
  ) => {
    const country = getCountryFromShortcut(countryShortcut)

    return { ...country, name: country?.country ?? '' }
  }

  return {
    countriesForList,
    organizationRolesForList,
    currencyExchangeForList,
    countriesList,
    languagesForList,
    getCountryFromShortcut,
    getCountryFullName,
    getCountryForListFromShortcut,
    refreshCountriesForList,
    privacyStatement,
    legalNotices,
    providerIdentification,
    instructionForUse,
    isAppInfo,
  }
}

export default useStaticData
