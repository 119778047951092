import { API } from '@/api'
import { API_BASE } from '@/services/config/api.config'
import { getCookie } from '@/services/auth-token.service'

export const createApi = () => {
  return new API.Api({
    baseUrl: API_BASE,
    baseApiParams: {
      credentials: 'include',
      referrerPolicy: 'strict-origin-when-cross-origin',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/app-v2.0+json'
      },
    },
    securityWorker: () => {
      let authHeaders = {}

      if(getCookie('useDisabledCookieMethod_') && getCookie('useDisabledCookieMethod_') == 'true'){
        if(getCookie('authorization_')){
          authHeaders = { ...authHeaders, 'Authorization': getCookie('authorization_') };
        }
      }

      return { headers: authHeaders }
    }
  })
}
