import { StaticDataState } from './types'

export const state: StaticDataState = {
  countriesList: [],
  subregionsList: undefined,
  currencyExchangeList: [],
  organizationRolesList: [],
  isLoading: false,
  appInfo: undefined,
  languagesList: [],
}
