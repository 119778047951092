import { tryOnScopeDispose } from '@vueuse/core'
import {
  InjectionKey,
  computed,
  inject,
  provide,
  shallowReactive,
  shallowRef,
  watch,
} from 'vue'

type PortalRegistry = {
  parent: PortalRegistry | undefined
  portals: {
    [name in string]?: HTMLElement | null | undefined
  }
}

const REGISTRY_TOKEN = Symbol() as InjectionKey<PortalRegistry>

export function createPortalRegistry() {
  const registry: PortalRegistry = {
    parent: inject(REGISTRY_TOKEN, undefined),
    portals: shallowReactive({}),
  }

  provide(REGISTRY_TOKEN, registry)

  return registry
}

export function usePortalRegistry() {
  return inject(REGISTRY_TOKEN, createPortalRegistry, true)
}

export function useRegisterPortalOutlet(getName: () => string) {
  const registry = usePortalRegistry()
  const element = shallowRef<HTMLElement | null>()

  watch(
    [getName, element],
    ([name, elem], [oldName]) => {
      if (oldName != null && name !== oldName) {
        delete registry.portals[oldName]
      }

      registry.portals[name] = elem
    },
    { immediate: true, flush: 'sync' },
  )

  tryOnScopeDispose(() => {
    delete registry.portals[getName()]
  })

  return element
}

export function usePortalOutlet(getName: () => string) {
  const registry = usePortalRegistry()

  return computed(() => {
    const name = getName()
    let reg: PortalRegistry | undefined = registry

    while (reg) {
      const elem = reg.portals[name]
      if (elem) return elem
      reg = reg.parent
    }

    return undefined
  })
}
