import { delay } from '@/utils'
import { shallowReactive } from 'vue'

type OrPromise<T> = T | Promise<T>
const cache = shallowReactive(new Map<string, OrPromise<SVGSVGElement>>())

export function loadSvg(src: string): OrPromise<SVGSVGElement> {
  let svgOrPromise = cache.get(src)
  if (!svgOrPromise) cache.set(src, (svgOrPromise = loadLoop(0)))

  return svgOrPromise

  async function loadLoop(tries: number): Promise<SVGSVGElement> {
    try {
      const response = await fetch(src)
      const xml = await response.text()
      const dom = new DOMParser().parseFromString(xml, 'text/xml')
      const svg = dom.getElementsByTagName('svg')[0]
      cache.set(src, svg)

      return svg
    } catch {
      // exponential backoff
      await delay(1000 * 1.4 ** tries)

      return loadLoop(tries + 1)
    }
  }
}
