import { API } from '@/api'
import { apiClient, errorHandler } from '@/services/data.service'
import { AppState } from '@/store/types'
import { ActionTree } from 'vuex'
import { ProductDetailsState } from './types'

export const actions: ActionTree<ProductDetailsState, AppState> = {
  async fetchProductsConnectedToCurrentProducts({ commit, dispatch, state }) {
    const productDetails = state.product
    if (!productDetails) {
      return
    }
    try {
      if (productDetails.materialData?.alternativeProducts?.length) {
        dispatch(
          'fetchAlternativeProducts',
          productDetails.materialData?.alternativeProducts,
        )
      } else {
        commit('updateAlternativeProducts', [])
      }
      if (productDetails.materialData?.relatedProducts?.length) {
        dispatch(
          'fetchRelatedProducts',
          productDetails.materialData?.relatedProducts,
        )
      } else {
        commit('updateRelatedProducts', [])
      }
    } catch (e) {
      errorHandler(e)
    }
  },

  async fetchProductDetailsByTitle({ commit, dispatch }, productTitle: string) {
    commit('updateIsLoading', true)
    commit('updateProductDetails', undefined)
    try {
      const productDetails: API.ExtendedProductResponseDto = (
        await apiClient.v3.detailsByTitleV3UsingGet(productTitle, {
          headers: {
            accept: 'application/json', // TODO: DHOR-393 - Fix - application/app-v2.0+json not work
          },
        })
      ).data
      commit('updateProductDetails', productDetails)

      await dispatch('fetchProductsConnectedToCurrentProducts')
    } catch (e) {
      errorHandler(e)
    }
    commit('updateIsLoading', false)
  },

  async fetchProductVariantDetailsById({ commit, dispatch }, id: string) {
    try {
      const productDetails: API.ExtendedProductResponseDto = (
        await apiClient.v3.detailsByIdV3UsingGet(Number(id), {
          headers: {
            accept: 'application/json', // TODO: DHOR-393 - Fix - application/app-v2.0+json not work
          },
        })
      ).data
      commit('updateProductDetails', productDetails)

      await dispatch('fetchProductsConnectedToCurrentProducts')
    } catch (e) {
      errorHandler(e)
    }
    commit('updateIsLoading', false)
  },

  async fetchAlternativeProducts({ commit }, ids: number[]) {
    commit('updateAlternativeProducts', [])
    try {
      const products: API.ProductRowV3ResponseDto[] = (
        await apiClient.v3.getProductsByIdsV3UsingGet(
          { ids },
          {
            headers: {
              accept: 'application/json', // TODO: DHOR-393 - Fix - application/app-v2.0+json not work
            },
          },
        )
      ).data.content
      commit('updateAlternativeProducts', products)
    } catch (e) {
      errorHandler(e)
    }
  },

  async fetchRelatedProducts({ commit }, ids: number[]) {
    commit('updateRelatedProducts', [])
    try {
      const products: API.ProductRowV3ResponseDto[] = (
        await apiClient.v3.getProductsByIdsV3UsingGet(
          { ids },
          {
            headers: {
              accept: 'application/json', // TODO: DHOR-393 - Fix - application/app-v2.0+json not work
            },
          },
        )
      ).data.content
      commit('updateRelatedProducts', products)
    } catch (e) {
      errorHandler(e)
    }
  },

  async reportIssue(_ctx, payload: API.ReportDataDto) {
    try {
      await apiClient.report.sendReportDataIssueUsingPost(payload)
    } catch (e) {
      errorHandler(e)
    }
  },
}
