import { FilterGroup, LegacyFilterLeaf } from '@/store/types'

export const devicesAnesthesia = () => {
  return FilterGroup({
    label: 'devices.anesthesia',
    children: [
      FilterGroup({
        label: 'devices.apollo',
        children: [
          LegacyFilterLeaf(1000, {
            label: 'devices.apollo',
            value: 'ANESTHESIA_APOLLO',
          }),
        ],
      }),

      FilterGroup({
        label: 'devices.atlan',
        selectAllChildren: {
          label: 'devices.allAtlan',
        },
        children: [
          LegacyFilterLeaf(1010, {
            label: 'devices.atlanA100',
            value: 'ANESTHESIA_ATLAN_A100',
          }),
          LegacyFilterLeaf(1011, {
            label: 'devices.atlanA100XL',
            value: 'ANESTHESIA_ATLAN_A100_XL',
          }),
          LegacyFilterLeaf(1012, {
            label: 'devices.atlanA300',
            value: 'ANESTHESIA_ATLAN_A300',
          }),
          LegacyFilterLeaf(1013, {
            label: 'devices.atlanA300XL',
            value: 'ANESTHESIA_ATLAN_A300_XL',
          }),
          LegacyFilterLeaf(1014, {
            label: 'devices.atlanA350',
            value: 'ANESTHESIA_ATLAN_A350',
          }),
          LegacyFilterLeaf(1015, {
            label: 'devices.atlanA350XL',
            value: 'ANESTHESIA_ATLAN_A350_XL',
          }),
        ],
      }),
      FilterGroup({
        label: 'devices.fabius',
        selectAllChildren: {
          label: 'devices.allFabius',
        },
        children: [
          LegacyFilterLeaf(1020, {
            label: 'devices.fabiusGs',
            value: 'ANESTHESIA_FABIUS_GS',
          }),
          LegacyFilterLeaf(1021, {
            label: 'devices.fabiusGsPremium',
            value: 'ANESTHESIA_FABIUS_GS_PREMIUM',
          }),
          LegacyFilterLeaf(1022, {
            label: 'devices.fabiusTiro',
            value: 'ANESTHESIA_FABIUS_TIRO',
          }),
          LegacyFilterLeaf(1023, {
            label: 'devices.fabiusMRI',
            value: 'ANESTHESIA_FABIUS_MRI',
          }),
          LegacyFilterLeaf(1024, {
            label: 'devices.fabiusPlusXL',
            value: 'ANESTHESIA_FABIUS_PLUS_XL',
          }),
          LegacyFilterLeaf(1025, {
            label: 'devices.fabiusPlus',
            value: 'ANESTHESIA_FABIUS_PLUS',
          }),
        ],
      }),
      FilterGroup({
        label: 'devices.perseus',
        children: [
          LegacyFilterLeaf(1030, {
            label: 'devices.perseusA500',
            value: 'ANESTHESIA_PERSEUS_A500',
          }),
        ],
      }),
      FilterGroup({
        label: 'devices.primus',
        selectAllChildren: {
          label: 'devices.allPrimus',
        },
        children: [
          LegacyFilterLeaf(1040, {
            label: 'devices.primusIe',
            value: 'ANESTHESIA_PRIMUS_IE',
          }),
          LegacyFilterLeaf(1041, {
            label: 'devices.primus',
            value: 'ANESTHESIA_PRIMUS',
          }),
        ],
      }),
      FilterGroup({
        label: 'devices.zeus',
        selectAllChildren: {
          label: 'devices.allZeus',
        },
        children: [
          LegacyFilterLeaf(1050, {
            label: 'devices.zeusIe',
            value: 'ANESTHESIA_ZEUS_IE',
          }),
          LegacyFilterLeaf(1051, {
            label: 'devices.zeus',
            value: 'ANESTHESIA_ZEUS',
          }),
        ],
      }),
    ],
  })
}

export const devicesVentilation = () => {
  return FilterGroup({
    label: 'devices.ventilation',
    children: [
      FilterGroup({
        label: 'devices.babylog',
        selectAllChildren: {
          label: 'devices.allBabylog',
        },
        children: [
          LegacyFilterLeaf(1100, {
            label: 'devices.babylog8000',
            value: 'VENTILATION_BABYLOG_8000',
          }),
          LegacyFilterLeaf(1103, {
            label: 'devices.babylogVN500',
            value: 'VENTILATION_BABYLOG_VN500',
          }),
          LegacyFilterLeaf(1102, {
            label: 'devices.babylogVN600',
            value: 'VENTILATION_BABYLOG_VN600',
          }),
          LegacyFilterLeaf(1101, {
            label: 'devices.babylogVN800',
            value: 'VENTILATION_BABYLOG_VN800',
          }),
        ],
      }),
      FilterGroup({
        label: 'devices.evita',
        selectAllChildren: {
          label: 'devices.allEvita',
        },
        children: [
          LegacyFilterLeaf(1111, {
            label: 'devices.evitaV300',
            value: 'VENTILATION_EVITA_V300',
          }),
          LegacyFilterLeaf(1110, {
            label: 'devices.evitaV500',
            value: 'VENTILATION_EVITA_V500',
          }),
          LegacyFilterLeaf(1113, {
            label: 'devices.evitaV600',
            value: 'VENTILATION_EVITA_V600',
          }),
          LegacyFilterLeaf(1112, {
            label: 'devices.evitaV800',
            value: 'VENTILATION_EVITA_V800',
          }),
          LegacyFilterLeaf(1114, {
            label: 'devices.evita2dura',
            value: 'VENTILATION_EVITA_2_DURA',
          }),
          LegacyFilterLeaf(1115, {
            label: 'devices.evita4',
            value: 'VENTILATION_EVITA_4',
          }),
          LegacyFilterLeaf(1116, {
            label: 'devices.evitaXL',
            value: 'VENTILATION_EVITA_XL',
          }),
        ],
      }),
      FilterGroup({
        label: 'devices.oxylog',
        selectAllChildren: {
          label: 'devices.allOxylog',
        },
        children: [
          LegacyFilterLeaf(1120, {
            label: 'devices.oxylogVE300',
            value: 'VENTILATION_OXYLOG_VE_300',
          }),
          LegacyFilterLeaf(1121, {
            label: 'devices.oxylog1000',
            value: 'VENTILATION_OXYLOG_1000',
          }),
          LegacyFilterLeaf(1122, {
            label: 'devices.oxylog2000Plus',
            value: 'VENTILATION_OXYLOG_2000_PLUS',
          }),
          LegacyFilterLeaf(1123, {
            label: 'devices.oxylog3000',
            value: 'VENTILATION_OXYLOG_3000',
          }),
          LegacyFilterLeaf(1124, {
            label: 'devices.oxylog3000Plus',
            value: 'VENTILATION_OXYLOG_3000_PLUS',
          }),
        ],
      }),
      FilterGroup({
        label: 'devices.pulmovista',
        children: [
          LegacyFilterLeaf(1130, {
            label: 'devices.pulmovista500',
            value: 'VENTILATION_PULMOVISTA_500',
          }),
        ],
      }),
      FilterGroup({
        label: 'devices.savina',
        selectAllChildren: {
          label: 'devices.allSavina',
        },
        children: [
          LegacyFilterLeaf(1140, {
            label: 'devices.savina300',
            value: 'VENTILATION_SAVINA_300',
          }),
          LegacyFilterLeaf(1141, {
            label: 'devices.savina300NIV',
            value: 'VENTILATION_SAVINA_300_NIV',
          }),
          LegacyFilterLeaf(1142, {
            label: 'devices.savina300Select',
            value: 'VENTILATION_SAVINA_300_SELECT',
          }),
          LegacyFilterLeaf(1143, {
            label: 'devices.savina300Classic',
            value: 'VENTILATION_SAVINA_300_CLASSIC',
          }),
          LegacyFilterLeaf(1144, {
            label: 'devices.savinaColor',
            value: 'VENTILATION_SAVINA_300_COLOR',
          }),
        ],
      }),
      FilterGroup({
        label: 'devices.carina',
        children: [
          LegacyFilterLeaf(1150, {
            label: 'devices.carina',
            value: 'VENTILATION_CARINA',
          }),
        ],
      }),
      FilterGroup({
        label: 'devices.aquapor',
        children: [
          LegacyFilterLeaf(1160, {
            label: 'devices.aquapor',
            value: 'VENTILATION_AQUAPOR',
          }),
        ],
      }),
    ],
  })
}

export const devicesThermoregulation = () => {
  return FilterGroup({
    label: 'devices.thermoregulation',
    children: [
      FilterGroup({
        label: 'devices.babyleo',
        children: [
          LegacyFilterLeaf(1200, {
            label: 'devices.babyleoTN500',
            value: 'THERMOREGULATION_BABYLEO',
          }),
        ],
      }),
      FilterGroup({
        label: 'devices.caleo',
        children: [
          LegacyFilterLeaf(1210, {
            label: 'devices.caleo',
            value: 'THERMOREGULATION_CALEO',
          }),
        ],
      }),
      FilterGroup({
        label: 'devices.babyroo',
        children: [
          LegacyFilterLeaf(1220, {
            label: 'devices.babyrooTn300',
            value: 'THERMOREGULATION_BABYROO_TN300',
          }),
        ],
      }),

      FilterGroup({
        label: 'devices.resuscitaire',
        children: [
          LegacyFilterLeaf(1230, {
            label: 'devices.resuscitaire',
            value: 'THERMOREGULATION_RESUSCITAIRE',
          }),
        ],
      }),
      FilterGroup({
        label: 'devices.babytherm',
        selectAllChildren: {
          label: 'devices.allBabytherm',
        },
        children: [
          LegacyFilterLeaf(1240, {
            label: 'devices.babytherm8004',
            value: 'THERMOREGULATION_BABYTHERM_8004',
          }),
          LegacyFilterLeaf(1241, {
            label: 'devices.babytherm8010',
            value: 'THERMOREGULATION_BABYTHERM_8010',
          }),
        ],
      }),
      FilterGroup({
        label: 'devices.isolette',
        selectAllChildren: {
          label: 'devices.allIsolette',
        },
        children: [
          LegacyFilterLeaf(1250, {
            label: 'devices.isolette8000plus',
            value: 'THERMOREGULATION_ISOLETTE_8000_PLUS',
          }),
          LegacyFilterLeaf(1251, {
            label: 'devices.isolette8000',
            value: 'THERMOREGULATION_ISOLETTE_8000',
          }),
          LegacyFilterLeaf(1252, {
            label: 'devices.isoletteC2000',
            value: 'THERMOREGULATION_ISOLETTE_C2000',
          }),
          LegacyFilterLeaf(1253, {
            label: 'devices.isoletteTI500',
            value: 'THERMOREGULATION_ISOLETTE_TI500',
          }),
        ],
      }),
      FilterGroup({
        label: 'devices.globeTrotter',
        selectAllChildren: {
          label: 'devices.allGlobeTrotter',
        },
        children: [
          LegacyFilterLeaf(1260, {
            label: 'devices.globeTrotterIC',
            value: 'THERMOREGULATION_GLOBE_TROTTER_IC',
          }),
          LegacyFilterLeaf(1261, {
            label: 'devices.globeTrotterGT5400',
            value: 'THERMOREGULATION_GLOBE_TROTTER_GT5400',
          }),
        ],
      }),
    ],
  })
}

export const devicesMonitoring = () => {
  return FilterGroup({
    label: 'devices.monitoring',
    children: [
      FilterGroup({
        label: 'devices.infinityAcuteCare',
        selectAllChildren: {
          label: 'devices.allInfinityAcute',
        },
        children: [
          LegacyFilterLeaf(1300, {
            label: 'devices.m540',
            value: 'MONITORING_INFINITY_ACUTE_CARE_M540',
          }),
          LegacyFilterLeaf(1301, {
            label: 'devices.acuteCareSystem',
            value: 'MONITORING_INFINITY_ACUTE_CARE_ACUTE_CARE_SYSTEM',
          }),
        ],
      }),
      FilterGroup({
        label: 'devices.infinityDeltaFamily',
        selectAllChildren: {
          label: 'devices.allInfinityDelta',
        },
        children: [
          LegacyFilterLeaf(1310, {
            label: 'devices.delta_XL',
            value: 'MONITORING_INFINITY_DELTA_FAMILY_DELTA',
          }),
          LegacyFilterLeaf(1311, {
            label: 'devices.kappaXLT',
            value: 'MONITORING_INFINITY_DELTA_FAMILY_KAPPA_XLT',
          }),
          LegacyFilterLeaf(1312, {
            label: 'devices.gammaXXL',
            value: 'MONITORING_INFINITY_DELTA_FAMILY_GAMMA_XXL',
          }),
          LegacyFilterLeaf(1313, {
            label: 'devices.gammaXL',
            value: 'MONITORING_INFINITY_DELTA_FAMILY_GAMMA_XL',
          }),
          LegacyFilterLeaf(1314, {
            label: 'devices.vistaXL',
            value: 'MONITORING_INFINITY_DELTA_FAMILY_VISTA_XL',
          }),
          LegacyFilterLeaf(1315, {
            label: 'devices.vista',
            value: 'MONITORING_INFINITY_DELTA_FAMILY_VISTA',
          }),
        ],
      }),
      FilterGroup({
        label: 'devices.m300_M300Plus',
        children: [
          LegacyFilterLeaf(1320, {
            label: 'devices.m300_M300Plus',
            value: 'MONITORING_M300_M300_PLUS',
          }),
        ],
      }),
      FilterGroup({
        label: 'devices.vista120',
        selectAllChildren: {
          label: 'devices.allVista120',
        },
        children: [
          LegacyFilterLeaf(1330, {
            label: 'devices.vista120A',
            value: 'MONITORING_VISTA_120_A',
          }),
          LegacyFilterLeaf(1331, {
            label: 'devices.vista120APlus',
            value: 'MONITORING_VISTA_120_A_PLUS',
          }),
          LegacyFilterLeaf(1332, {
            label: 'devices.vista120C',
            value: 'MONITORING_VISTA_120_C',
          }),
          LegacyFilterLeaf(1333, {
            label: 'devices.vista120CPlus',
            value: 'MONITORING_VISTA_120_C_PLUS',
          }),
          // VISTA S
          LegacyFilterLeaf(1334, {
            label: 'devices.vista120SA',
            value: 'MONITORING_VISTA_120_S_A',
          }),
          LegacyFilterLeaf(1335, {
            label: 'devices.vista120SAPlus',
            value: 'MONITORING_VISTA_120_S_A_PLUS',
          }),
          LegacyFilterLeaf(1336, {
            label: 'devices.vista120S_C',
            value: 'MONITORING_VISTA_120_S_C',
          }),
          LegacyFilterLeaf(1337, {
            label: 'devices.vista120SCPlus',
            value: 'MONITORING_VISTA_120_S_C_PLUS',
          }),
        ],
      }),
      FilterGroup({
        label: 'devices.vista120SC',
        selectAllChildren: {
          label: 'devices.allVista120SC',
        },
        children: [
          LegacyFilterLeaf(1340, {
            label: 'devices.vista120SCModelA',
            value: 'MONITORING_VISTA_120SC_MODEL_A',
          }),
          LegacyFilterLeaf(1341, {
            label: 'devices.vista120SCModelB',
            value: 'MONITORING_VISTA_120SC_MODEL_B',
          }),
          LegacyFilterLeaf(1342, {
            label: 'devices.vista120SCModelC',
            value: 'MONITORING_VISTA_120SC_MODEL_C',
          }),
          LegacyFilterLeaf(1343, {
            label: 'devices.vista120SCModelD',
            value: 'MONITORING_VISTA_120SC_MODEL_D',
          }),
          LegacyFilterLeaf(1344, {
            label: 'devices.vista120SCModelE',
            value: 'MONITORING_VISTA_120SC_MODEL_E',
          }),
        ],
      }),
      FilterGroup({
        label: 'devices.vista300',
        selectAllChildren: {
          label: 'devices.allVista300',
        },
        children: [
          LegacyFilterLeaf(1360, {
            label: 'devices.vista300ModelA',
            value: 'MONITORING_VISTA_300_MODEL_A',
          }),
          LegacyFilterLeaf(1361, {
            label: 'devices.vista300ModelC',
            value: 'MONITORING_VISTA_300_MODEL_C',
          }),
        ],
      }),
      FilterGroup({
        label: 'devices.tofScan',
        children: [
          LegacyFilterLeaf(1350, {
            label: 'devices.tofScan',
            value: 'MONITORING_TOFSCAN',
          }),
        ],
      }),
    ],
  })
}

export const devicesOther = () => {
  return FilterGroup({
    label: 'devices.other',
    children: [
      LegacyFilterLeaf(145, {
        label: 'devices.polarisComfort',
        value: 'OTHER_POLARIS_COMFORT',
      }),
      LegacyFilterLeaf(146, {
        label: 'devices.polarisPro',
        value: 'OTHER_POLARIS_PRO',
      }),
      LegacyFilterLeaf(147, {
        label: 'devices.polarisProPlus',
        value: 'OTHER_POLARIS_PRO_PLUS',
      }),
      LegacyFilterLeaf(140, {
        label: 'devices.polaris100',
        value: 'OTHER_POLARIS_100',
      }),
      LegacyFilterLeaf(141, {
        label: 'devices.polaris200',
        value: 'OTHER_POLARIS_200',
      }),
      LegacyFilterLeaf(142, {
        label: 'devices.polaris100_200',
        value: 'OTHER_POLARIS_100_200',
      }),
      LegacyFilterLeaf(143, {
        label: 'devices.polaris600',
        value: 'OTHER_POLARIS_600',
      }),
      LegacyFilterLeaf(144, {
        label: 'devices.polarisMultimedia',
        value: 'OTHER_POLARIS_MULTIMEDIA',
      }),
    ],
  })
}

export const deviceGroup = () => {
  return FilterGroup({
    label: 'search.deviceGroups',
    children: [
      devicesAnesthesia(),
      devicesVentilation(),
      devicesThermoregulation(),
      devicesMonitoring(),
      devicesOther(),
    ],
  })
}
