import { API } from '@/api'
import { AppState } from '@/store/types'

export type ApiScpStatus = NonNullable<
  API.DisplayUserProfileResponseDto['scpStatus']
>

export const rejectedScpStatus = 'REJECTED' as const satisfies ApiScpStatus
export const pendingScpStatus = 'PENDING' as const satisfies ApiScpStatus
export const onboardingRequiredScpStatus =
  'ONBOARDING_REQUIRED' as const satisfies ApiScpStatus
export const acceptedScpStatus = 'ACCEPTED' as const satisfies ApiScpStatus

export const allScpStatuses = [
  acceptedScpStatus,
  rejectedScpStatus,
  pendingScpStatus,
  onboardingRequiredScpStatus,
] as const satisfies readonly ApiScpStatus[]

export const allScpStatusesExcept = (
  statuses: ApiScpStatus[],
): ApiScpStatus[] => {
  return allScpStatuses.filter((status) => !statuses.includes(status))
}

export const checkStatus = (
  state: AppState,
  scpStatuses: (ApiScpStatus | undefined)[],
): boolean => {
  return scpStatuses.includes(state.user!.scpStatus)
}
