<template>
  <div class="triptych">
    <div class="side side-left">
      <slot name="side-left" />
    </div>
    <div class="main">
      <slot />
    </div>
    <div class="side side-right">
      <slot name="side-right" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.triptych {
  display: flex;
  flex-direction: row;

  .side {
    flex: 1 0;
    display: flex;
    align-items: center;
    gap: 1rem;
    @include breakpoint-lte-md {
      display: none;
    }

    &-right {
      justify-content: flex-end;
    }
  }
  .main {
    flex: 20 20;
    display: flex;
    max-width: $main-content-width;
    flex-direction: column;
    @include breakpoint-lte-md {
      margin: 0 1rem;
    }
  }
}
</style>
