import { Module } from 'vuex'

import { AppState } from '@/store/types'
import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'
import { UsersState } from './types'

const users: Module<UsersState, AppState> = {
  namespaced: true,
  actions,
  mutations,
  state,
}

export default users
