import { API } from '@/api'
import { MutationTree } from 'vuex'
import { setPersisted } from './persistenceHelper'
import {
  AppState,
  ModalVisibility,
  PersistedState,
  UserSortingType,
} from './types'

export const mutations: MutationTree<AppState> = {
  updateOnboardingNeeded(state, payload: boolean) {
    state.onboardingNeeded = payload
  },
  updateUser(state, payload: API.DisplayUserProfileResponseDto | undefined) {
    state.user = payload
  },

  updateUserListSortingType(state, payload: UserSortingType) {
    state.userListSortingType = payload
  },

  updateIsLoading(state, payload: boolean) {
    state.isLoading = payload
  },

  updateModalVisibility(state, payload: Partial<ModalVisibility>) {
    state.showModal = {
      ...state.showModal,
      ...payload,
    }
  },

  updateIsAutocompleteDataLoading(state, payload: boolean) {
    state.isAutocompleteDataLoading = payload
  },

  updateSearchAutocomplete(
    state,
    payload: {
      searchValue: string
      data: API.ResultListWithTotalCountDtoOfProductResponseDto
      loadTime: number
    },
  ) {
    const { searchValue, data, loadTime } = payload
    state.autocompleteData = {
      searchValue,
      searchHints: data.content,
      totalCount: data.totalCount,
      searchHintsLoadTime: loadTime,
    }
  },

  updatePersisted(state, patch: Partial<PersistedState>) {
    state.persisted = { ...state.persisted, ...patch }

    setPersisted(state.persisted)
  },

  updateProductCounters(state, payload: API.ProductCountersResponseDto) {
    state.productCounters = payload
  },

  updateKeyUserPermissions(state, payload: API.SubregionDto[]) {
    state.keyUserPermissions = payload
  },
}
