import { useDebouncedSyncRef } from '@/utils'
import { ref, watch } from 'vue'

export const useAppSearchInput = (
  props: {
    immediate?: boolean
  },
  onModelValueUpdateEmit: (phrase: string) => void,
) => {
  const outer = ref('')

  const [inner, { flush }] = useDebouncedSyncRef(
    () => (props.immediate ? 0 : 800),
    outer,
  )

  watch(outer, (newValue) => {
    onModelValueUpdateEmit(newValue)
  })

  return {
    inner,
    flush,
  }
}
