import { Locale, format, formatDistanceToNowStrict } from 'date-fns'
import { de, enUS, es, fr, it, ja, pt } from 'date-fns/locale'
import { useI18n } from 'vue-i18n'

interface LanguageDateFns {
  en: Locale
  de: Locale
  es: Locale
  fr: Locale
  it: Locale
  ja: Locale
  pt: Locale
}

export const languageDateFns: LanguageDateFns = {
  en: enUS,
  de,
  es,
  fr,
  it,
  ja,
  pt,
}

const useDateFNS = () => {
  const i18n = useI18n()
  const locale: Locale =
    languageDateFns[i18n.locale.value as keyof LanguageDateFns]

  const formatDistanceToNowUsingLocale = (date: Date) => {
    return formatDistanceToNowStrict(date, {
      ...locale,
      roundingMethod: 'ceil',
    })
  }

  const formatHoursAndDate = (date: Date) => {
    return format(date, 'p - P')
  }

  return { formatDistanceToNowUsingLocale, formatHoursAndDate }
}

export default useDateFNS
