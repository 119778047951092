import { useBreakpoints } from '@vueuse/core'

// ⚡ ============================================== ⚡
// 🚨  Keep this file in sync with breakpoints.scss  🚨
// ☠️ ============================================== ☠️
export const XS_PX = 640
export const SM_PX = 990
export const MD_PX = 1100
export const LG_PX = 1280

const breakpoints = useBreakpoints({
  xs: XS_PX,
  sm: SM_PX,
  md: MD_PX,
  lg: LG_PX,
})

export const isSmallerThanExtraSmall = breakpoints.smaller('xs')
export const isSmallerThanSmall = breakpoints.smaller('sm')
export const isSmallerThanMedium = breakpoints.smaller('md')
export const isSmallerThanLarge = breakpoints.smaller('lg')
export const isLargerThanExtraSmall = breakpoints.greater('xs')
export const isLargerThanSmall = breakpoints.greater('sm')
export const isLargerThanMedium = breakpoints.greater('md')
export const isLargerThanLarge = breakpoints.greater('lg')
export const isInBetweenSmallAndMedium = breakpoints.between('sm', 'md')
