import {
  FormContextKey,
  useForm,
  useSubmitCount,
  type FieldMeta,
  type FormOptions,
} from 'vee-validate'
import { inject, provide, type InjectionKey } from 'vue'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyForm = Record<string, any>

type AppFormOptionsPartial = {
  /**
   * Form name is required to properly render translated field names as
   * ```ts
   * $t(`forms.${formName}.fields.${fieldName}`)
   * ```
   */
  formName: string

  /**
   * When showError returns true for given {@link FieldMeta} then field will be
   * highlighted in error color. If not provided then default implementation
   * will be used. Please see {@link defaultShowError} for details.
   */
  showError?: (meta: FieldMeta<unknown>) => boolean
}

type AppFormOptionsFull = Required<AppFormOptionsPartial>

type AllFormOptions<T extends AnyForm = AnyForm> = FormOptions<T> &
  AppFormOptionsPartial

const APP_FORM_OPTIONS_KEY = Symbol() as InjectionKey<AppFormOptionsFull>

export function useAppForm<T extends AnyForm>(opts: AllFormOptions<T>) {
  const form = useForm(opts)

  provide(APP_FORM_OPTIONS_KEY, {
    formName: opts.formName,
    showError: opts.showError || defaultShowError(),
  })

  return form
}

export function useExistingForm() {
  const form = inject(FormContextKey)
  if (!form) {
    throw Error(`Couldn't find form. Did you forget useAppForm?`)
  }

  return form
}

export function useExistingFormOptions() {
  const opts = inject(APP_FORM_OPTIONS_KEY)
  if (!opts) {
    throw Error(`Couldn't find form options. Did you forget useAppForm?`)
  }

  return opts
}

function defaultShowError(): AppFormOptionsFull['showError'] {
  try {
    const submitCount = useSubmitCount()

    return (fieldMeta) => {
      if (!fieldMeta.validated || fieldMeta.valid) return false

      return submitCount.value > 0 || fieldMeta.touched
    }
  } catch {
    return (fieldMeta) => {
      if (!fieldMeta.validated || fieldMeta.valid) return false

      return fieldMeta.touched
    }
  }
}

export function onlyInvalidShowError(): AppFormOptionsFull['showError'] {
  return (fieldMeta) => {
    return !fieldMeta.valid
  }
}
